import { format } from 'date-fns'
import * as jsPDF from 'jspdf'
import { formatNumber, getValue, downloadFile } from '@/utils/genericUtils'
const { formatInTimeZone } = require('date-fns-tz')
import request from '@/utils/request'
import { set } from 'nprogress'

const state = {
    listaReservas: []
}
const maxWidth = 209 // Ancho máximo de la hoja en mm

const mutations = {}

const actions = {
    async partPago({ rootState }, payload) {
        try {
            // console.log('payload_partPago', payload)
            const doc = new jsPDF('p', 'mm', [maxWidth, 650])
            const today = format(new Date(), 'dd-MM-yyyy hh:mm')
            const { user } = rootState.users
            const bussines = rootState.bussines.bussines
            const {
                idTipoVenta,
                productos,
                atributos,
                finalizado,
                numeroDocumento,
                id,
                mixtos,
                full_name,
                masajes,
                adelanto,
                importeAbonado,
                privados,
                id_reserva_origen,
                importeAbonadoSoles,
                idTipoMoneda
            } = payload.datos
            const { tipoMoneda, total, saldo } = payload.pagos
            const { listaPagos } = payload
            console.log(':listaPagos', listaPagos)
            //console.log('tipoPago', payload.pagos)
            const _productos = Array.isArray(productos) ? productos : JSON.parse(productos)
            const notCortesyProd = _productos.filter((val) => {
                if (val.id_category != '4' || val.id_category != '5') {
                    return val
                }
            })

            //console.log('reservas  ', payload)
            //console.log('productos  ', _productos, ' -- ', notCortesyProd)
            const estado = id
            let y = 4
            const x = 4
            doc.setProperties({
                title: `ticket_${estado}_id_${Date.now()}.pdf`
            })
            var img = new Image()
            let sedeId = bussines.id == 1 ? 'kunte' : 'cuba'
            img.src = require(`../../assets/logo_printer_${bussines.id}.png`)
            doc.addImage(img, 'PNG', x + 10, y, 40, 25)
            y += 28
            doc.setFontSize(6)
            doc.text(12, y, rootState.sedes.sede.direccion)

            y += 6
            doc.setFontSize(7)
            doc.text(x, y, `DOC.     : ${numeroDocumento}`)
            y += 4
            doc.text(x, y, `CLIENTE  : ${full_name}`)
            y += 4
            doc.text(x, y, `FECHA DE EMISION : ${today}`)
            y += 4
            doc.text(x, y, `CAJERO : ${user.nombres} ${user.apellidos.slice(0, 1)}.`)
            y += 4
            doc.text(x, y, `TICKET : ${id}`)
            y += 6
            doc.setFontSize(6)
            doc.setFontType('bold')
            doc.text(x, y, 'UNIT')
            doc.text(x + 8, y, 'DESCRIPCION')
            doc.text(x + 41, y, 'P.UNIT')
            doc.text(x + 52, y, 'IMPORTE')
            y += 2
            doc.line(x, y, 70, y)
            doc.setFontType('normal')
            //console.log(notCortesyProd)

            let subTotal = 0

            doc.setFontSize(8)

            let ya = { subTotal: 0 }
            let yp = { subTotal: 0 }
            let ym = { subTotal: 0 }
            let ys = { subTotal: 0 }
            let yo = { subTotal: 0 }

            //console.log('here-2')

            yp = setList(doc, privados, x, y, 1)
            y = yp.y
            ym = setList(doc, mixtos, x, y, 1)
            y = ym.y
            ys = setList(doc, masajes, x, y, 1)
            y = ys.y
            yo = setList(doc, notCortesyProd, x, y, 2)
            y = yo.y
            // RESTAR ADELANTO DE UNA RESERVA
            if (importeAbonadoSoles > 0) {
                const listAdelanto = [
                    {
                        quantity: 1,
                        description: '[ADELANTO]',
                        price: -1 * importeAbonadoSoles,
                        subTotal: -1 * importeAbonadoSoles
                    }
                ]
                ya = setList(doc, listAdelanto, x, y, 1)
                y = ya.y
            }

            subTotal = yp.subTotal + ym.subTotal + ys.subTotal + yo.subTotal + ya.subTotal

            doc.setFontSize(8)
            doc.setFontType('bold')
            y += 2
            doc.line(x, y, 70, y)
            y += 5
            doc.text(x, y, 'IMPORTE TOTAL:')

            /* doc.text(x + 54, y, `S/. ${subTotal}`) */
            //console.log('idTipoMoneda', idTipoMoneda)
            //console.log('subTotal', subTotal)
            doc.text(x + 47, y, `${formatNumber(subTotal, idTipoMoneda)}`)
            y += 7

            doc.text(x, y, 'PAGOS:')
            y += 5
            doc.text(x, y, `*ADELANTO:`)
            doc.text(x + 47, y, `${formatNumber(Number(total) - Number(saldo), tipoMoneda === '$' ? 2 : 1)}`)
            y += 5
            doc.text(x, y, `*SALDO:`)
            doc.text(x + 47, y, `${formatNumber(Number(saldo), tipoMoneda === '$' ? 2 : 1)}`)
            y += 5

            doc.text(x, y, `*METODO DE PAGO:`)
            if (listaPagos.length == 0) doc.text(x + 47, y, 'SIN PAGO REALIZADO')
            listaPagos.map((val) => {
                y += 5
                doc.text(x, y, `${val.tipoMoneda} ${val.importe} - ${val.tipoPago}`)
            })

            y += 5
            doc.setFontType('normal')
            doc.setFontSize(6)
            y += 10
            doc.text(x + 15, y, 'GRACIAS POR SU PREFERENCIA')
            y += 5
            doc.text(6, y, `puede visitarnos en nuestras diferentes sedes: ${bussines.url}`)

            const fileName = `ticket_${bussines.shortName}_${estado}_id_${Date.now()}.pdf`
            //console.log('here -- ', fileName)
            doc.output('save', fileName)
        } catch (error) {
            //console.log('error ', error)
        }
    },
    async reservation({ rootState }, payload) {
        try {
            const doc = new jsPDF('p', 'mm', [maxWidth, 650])
            const today = format(new Date(), 'dd-MM-yyyy hh:mm')
            const { user } = rootState.users
            const bussines = rootState.bussines.bussines
            const {
                idTipoVenta,
                productos,
                finalizado,
                numeroDocumento,
                id,
                mixtos,
                full_name,
                masajes,
                adelanto,
                importeAbonado,
                privados,
                id_reserva_origen,
                importeAbonadoSoles,
                idTipoMoneda
            } = payload
            //console.log('productos ', productos)
            const _productos = Array.isArray(productos) ? productos : JSON.parse(productos)
            const notCortesyProd = _productos.filter((val) => {
                if (val.id_category != '4' || val.id_category != '5') {
                    return val
                }
            })

            //console.log('reservas  ', payload)
            //console.log('productos  ', _productos, ' -- ', notCortesyProd)
            const estado = finalizado == 1 ? id : 'preliminar'
            let y = 4
            const x = 4
            doc.setProperties({
                title: `ticket_${estado}_id_${Date.now()}.pdf`
            })
            var img = new Image()
            let sedeId = bussines.id == 1 ? 'kunte' : 'cuba'
            img.src = require(`../../assets/logo_printer_${bussines.id}.png`)
            doc.addImage(img, 'PNG', x + 10, y, 40, 25)
            y += 28
            doc.setFontSize(6)
            doc.text(12, y, rootState.sedes.sede.direccion)

            y += 6
            doc.setFontSize(7)
            doc.text(x, y, `DOC.     : ${numeroDocumento}`)
            y += 4
            doc.text(x, y, `CLIENTE  : ${full_name}`)
            y += 4
            doc.text(x, y, `FECHA DE EMISION : ${today}`)
            y += 4
            doc.text(x, y, `CAJERO : ${user.nombres} ${user.apellidos.slice(0, 1)}.`)
            y += 4
            doc.text(x, y, `TICKET : ${finalizado == 1 ? id : 'PRELIMINAR'}`)
            y += 6
            doc.setFontSize(6)
            doc.setFontType('bold')
            doc.text(x, y, 'UNIT')
            doc.text(x + 8, y, 'DESCRIPCION')
            doc.text(x + 41, y, 'P.UNIT')
            doc.text(x + 52, y, 'IMPORTE')
            y += 2
            doc.line(x, y, 70, y)
            doc.setFontType('normal')
            //console.log(notCortesyProd)

            let subTotal = 0

            doc.setFontSize(8)

            let ya = { subTotal: 0 }
            let yp = { subTotal: 0 }
            let ym = { subTotal: 0 }
            let ys = { subTotal: 0 }
            let yo = { subTotal: 0 }

            //console.log('here-2')
            yp = setList(doc, privados, x, y, 1)
            y = yp.y
            ym = setList(doc, mixtos, x, y, 1)
            y = ym.y
            ys = setList(doc, masajes, x, y, 1)
            y = ys.y
            yo = setList(doc, notCortesyProd, x, y, 2)
            y = yo.y
            // RESTAR ADELANTO DE UNA RESERVA
            if (importeAbonadoSoles > 0) {
                const listAdelanto = [
                    {
                        quantity: 1,
                        description: '[ADELANTO]',
                        price: -1 * importeAbonadoSoles,
                        subTotal: -1 * importeAbonadoSoles
                    }
                ]
                ya = setList(doc, listAdelanto, x, y, 1)
                y = ya.y
            }

            subTotal = yp.subTotal + ym.subTotal + ys.subTotal + yo.subTotal + ya.subTotal

            doc.setFontSize(8)
            doc.setFontType('bold')
            y += 2
            doc.line(x, y, 70, y)
            y += 5
            doc.text(x, y, 'IMPORTE TOTAL:')
            /* doc.text(x + 54, y, `S/. ${subTotal}`) */

            doc.text(x + 47, y, `${formatNumber(subTotal, idTipoMoneda)}`)

            doc.setFontType('normal')
            doc.setFontSize(6)
            y += 10
            doc.text(x + 15, y, 'GRACIAS POR SU PREFERENCIA')
            y += 5
            doc.text(6, y, `puede visitarnos en nuestras diferentes sedes: ${bussines.url}`)

            const fileName = `ticket_${bussines.shortName}_${estado}_id_${Date.now()}.pdf`
            //console.log('here -- ', fileName)
            doc.output('save', fileName)
        } catch (error) {
            //console.log('error ', error)
        }
    },
    async reservationSnPrice({ rootState }, payload) {
        try {
            const doc = new jsPDF('p', 'mm', [maxWidth, 650])
            const today = format(new Date(), 'dd-MM-yyyy hh:mm')
            const { user } = rootState.users
            const bussines = rootState.bussines.bussines
            const {
                idTipoVenta,
                productos,
                finalizado,
                numeroDocumento,
                id,
                mixtos,
                full_name,
                masajes,
                adelanto,
                importeAbonado,
                privados,
                id_reserva_origen,
                importeAbonadoSoles,
                idTipoMoneda
            } = payload
            //console.log('productos ', productos)
            const _productos = Array.isArray(productos) ? productos : JSON.parse(productos)
            const notCortesyProd = _productos.filter((val) => {
                if (val.id_category != '4' || val.id_category != '5') {
                    return val
                }
            })

            //console.log('reservas  ', payload)
            //console.log('productos  ', _productos, ' -- ', notCortesyProd)
            const estado = id
            let y = 4
            const x = 4
            doc.setProperties({
                title: `ticket_${estado}_id_${Date.now()}.pdf`
            })
            var img = new Image()

            let sedeId = bussines.id == 1 ? 'kunte' : 'cuba'
            img.src = require(`../../assets/logo_printer_${bussines.id}.png`)
            doc.addImage(img, 'PNG', x + 10, y, 40, 25)
            y += 28
            doc.setFontSize(6)
            doc.text(12, y, rootState.sedes.sede.direccion)

            y += 6
            doc.setFontSize(7)
            doc.text(x, y, `DOC.     : ${numeroDocumento}`)
            y += 4
            doc.text(x, y, `CLIENTE  : ${full_name}`)
            y += 4
            doc.text(x, y, `FECHA DE EMISION : ${today}`)
            y += 4
            doc.text(x, y, `CAJERO : ${user.nombres} ${user.apellidos.slice(0, 1)}.`)
            y += 4
            doc.text(x, y, `TICKET : ${id}`)
            y += 6
            doc.setFontSize(6)
            doc.setFontType('bold')
            doc.text(x, y, 'UNIT')
            doc.text(x + 12, y, 'DESCRIPCION')

            y += 2
            doc.line(x, y, 70, y)
            doc.setFontType('normal')
            //console.log(notCortesyProd)

            let subTotal = 0

            doc.setFontSize(8)

            let ya = { subTotal: 0 }
            let yp = { subTotal: 0 }
            let ym = { subTotal: 0 }
            let ys = { subTotal: 0 }
            let yo = { subTotal: 0 }
            const _notCortesyProd = notCortesyProd.map((z) => ({ ...z, description: `${z.description}${z.internal_id ? ' || ' + z.internal_id : ''}` }))
            console.log('_notCortesyProd', _notCortesyProd)
            //console.log('here-2')
            yp = setList2(doc, privados, x, y, 1)
            y = yp.y
            ym = setList2(doc, mixtos, x, y, 1)
            y = ym.y
            ys = setList2(doc, masajes, x, y, 1)
            y = ys.y
            yo = setList2(doc, _notCortesyProd, x, y, 2)
            y = yo.y
            // RESTAR ADELANTO DE UNA RESERVA
            if (importeAbonadoSoles > 0) {
                const listAdelanto = [
                    {
                        quantity: 1,
                        description: '[ADELANTO]',
                        price: -1 * importeAbonadoSoles,
                        subTotal: -1 * importeAbonadoSoles
                    }
                ]
                ya = setList2(doc, listAdelanto, x, y, 1)
                y = ya.y
            }

            subTotal = yp.subTotal + ym.subTotal + ys.subTotal + yo.subTotal + ya.subTotal

            doc.setFontSize(8)
            doc.setFontType('bold')
            y += 2
            doc.line(x, y, 70, y)
            y += 5

            doc.setFontType('normal')
            doc.setFontSize(6)
            y += 10
            doc.text(x + 15, y, 'GRACIAS POR SU PREFERENCIA')
            y += 5
            doc.text(6, y, `puede visitarnos en nuestras diferentes sedes: ${bussines.url}`)

            const fileName = `ticket_${bussines.shortName}_${estado}_id_${Date.now()}.pdf`
            //console.log('here -- ', fileName)
            const myFile = doc.output()
            //console.log('file ', myFile)

            // return await downloadFile(myFile, fileName, 'pdf', true)

            doc.output('save', fileName)
        } catch (error) {
            //console.log('error ', error)
        }
    },
    async ordenService({ rootState }, payload) {
        try {
            // console.log('payloadxcc', payload)
            const doc = new jsPDF('p', 'mm', [maxWidth, 650])
            const today = format(new Date(), 'dd-MM-yyyy hh:mm')
            const { user } = rootState.users
            const bussines = rootState.bussines.bussines
            const {
                numeroDocumento,
                id,
                full_name,
                service,
                importeAbonadoSoles,
                sede,
                atributos,
                correo,
                celularVendedora,
                celularContacto,
                direccion,
                departamento,
                provincia,
                distrito,
                vendedora
            } = payload
            console.log('service---', service)
            // const { data } = await request({
            //   url: `/ms/migraciones/v1.0/item/servicios`,
            //   method: 'POST',
            //   headers: {
            //     'content-type': 'application/json'
            //   },
            //   data: {
            //     idSede: rootState.users.user.idSede,
            //     servicio: service
            //   }
            // })
            // console.log("data----", data)
            const estado = id
            let y = 4
            const x = 4
            doc.setProperties({
                title: `ticket_${estado}_id_${Date.now()}.pdf`
            })
            var img = new Image()
            let sedeId = bussines.id == 1 ? 'kunte' : 'cuba'
            img.src = require(`../../assets/logo_printer_${bussines.id}.png`)
            doc.addImage(img, 'PNG', x + 10, y, 40, 25)
            y += 28
            doc.setFontSize(6)
            doc.text(12, y, rootState.sedes.sede.direccion)

            y += 4
            doc.setFontSize(6)
            doc.setFontType('bold')
            doc.text(23, y, `SUCURSAL: ${sede}`)
            doc.setFontType('normal')

            y += 2
            doc.setFontSize(6)
            doc.text(17, y, `${correo} - ${celularVendedora}`)

            y += 8
            doc.setFontSize(10)
            doc.text(5, y, `ORDEN DE SERVICIO  Nº ${id}`)
            y += 2
            doc.line(x, y, 70, y)

            y += 3
            doc.setFontSize(7)
            doc.text(x + 2, y, `Cliente:`)
            doc.setFontType('bold')
            doc.text(x + 17, y, full_name)
            doc.setFontType('normal')

            y += 4
            doc.setFontSize(7)
            doc.text(x + 2, y, `Nº Documento:`)
            doc.setFontType('bold')
            doc.text(x + 22, y, atributos.documentoReceptor)
            doc.setFontType('normal')

            y += 4
            doc.setFontSize(7)
            doc.text(x + 2, y, `Razón Social:`)
            doc.setFontType('bold')
            doc.text(x + 24, y, atributos.fullNameReceptor)
            doc.setFontType('normal')

            y += 4
            doc.setFontSize(7)
            doc.text(x + 2, y, `Teléfono:`)
            doc.setFontType('bold')
            doc.text(x + 17, y, celularContacto)
            doc.setFontType('normal')

            y += 4
            doc.setFontSize(7)
            doc.text(x + 2, y, `Dirección:`)
            doc.setFontType('bold')
            doc.text(x + 17, y, direccion)
            doc.setFontType('normal')

            y += 4
            doc.setFontSize(7)
            doc.text(x + 2, y, `Lugar:`)
            doc.setFontType('bold')
            doc.text(x + 17, y, `${departamento} - ${provincia} - ${distrito}`)
            doc.setFontType('normal')

            y += 2
            doc.line(x, y, 70, y)

            y += 5
            doc.setFontSize(7)
            doc.text(x, y, `DOC.     : ${numeroDocumento}`)
            y += 4
            doc.text(x, y, `FECHA DE EMISION : ${today}`)
            y += 4
            doc.text(x, y, `VENDEDORA : ${vendedora}`)

            y += 6
            doc.line(x, y, 70, y)
            doc.setFontType('blob')

            y += 3
            doc.setFontSize(6)
            doc.setFontType('bold')
            doc.text(x, y, 'DESCRIPCION')

            let subTotal = 0

            doc.setFontSize(8)

            let ya = { subTotal: 0 }
            let yp = { subTotal: 0 }

            //console.log('here-2',data)
            yp = setList3(doc, service, x, y, 1)
            y = yp.y
            // RESTAR ADELANTO DE UNA RESERVA
            if (importeAbonadoSoles > 0) {
                console.log('importeAbonadoSoles---', importeAbonadoSoles)
                const listAdelanto = [
                    {
                        quantity: 1,
                        description: '[ADELANTO]',
                        price: -1 * importeAbonadoSoles,
                        subTotal: -1 * importeAbonadoSoles
                    }
                ]
                ya = setList2(doc, listAdelanto, x, y, 1)
                y = ya.y
            }
            console.log('here 4')
            subTotal = yp.subTotal
            console.log('subTotal--', subTotal)
            doc.setFontSize(8)
            doc.setFontType('bold')
            y += 2
            doc.line(x, y, 70, y)
            y += 5

            doc.setFontType('normal')
            doc.setFontSize(1)
            y += 10
            doc.text(x + 15, y, 'GRACIAS POR SU PREFERENCIA')
            y += 5
            doc.text(5, y, `Puede visitarnos en nuestras diferentes sedes: ${bussines.url}`)

            const fileName = `ticket_${bussines.shortName}_${estado}_id_${Date.now()}.pdf`
            //console.log('here -- ', fileName)
            const myFile = doc.output()
            //console.log('file ', myFile)

            //await downloadFile(myFile, fileName, 'pdf', true)

            doc.output('save', fileName)
        } catch (error) {
            console.error('error ', error)
        }
    },
    async detalleCajaCarbone({ rootState }, payload) {
        const tm_pagos = await axios({
            url: `/api-loopback/tm_pagos/reporte/caja/${payload.fechaConsulta}`,
            // url: `/api-loopback/tm_pagos?filter={ "where": { "fechaPago":"${payload.fechaConsulta}"}}`,
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            }
        })
        console.log('tm_pagos', tm_pagos.data)
        const bussines = rootState.bussines.bussines
        const bancoDestino = payload.listTipoBancoDestinoOwn
        console.log('bancoDestino -->', bancoDestino)
        const tipoPago = rootState.utilities.paymentMethod
        const tipoComprobante = rootState.utilities.tipoComprobante
        const sedes = rootState.sedes.sede
        let pagos = []
        const _productos = []
        //const productos = renameList(payload.itemsproductos, 'subTotal')
        let productos = getAllItems(payload.ventasAll, rootState.utilities.tipoMoneda)
        productos.map((x) => {
            console.log('x', x)
            const payment = tm_pagos.data.filter((y) => y.idVenta == x.idTicket)
            console.log('payment', payment)

            payment.map((k) => {
                console.log('k', k)
                const findBanco = bancoDestino.find((y) => y.id == k.idBancoDestinoExternal)
                pagos.push({ moneda: k.idTipoMoneda == 1 ? 'S/.' : '$', banco: findBanco?.description || '', importe: k.importe })
            })
            _productos.push({
                ...x,
                pagos
            })
            pagos = []
        })

        console.log('pagos', pagos)
        console.log('_productos', _productos)
        let _gastos = renameList(payload.itemsgastos, 'importe')
        console.log('_gastos', _gastos)
        const identificador = Date.now()
        let i = 0
        //console.log('tipoPago ', tipoPago)
        //console.log('_gastos ', _gastos)
        console.log('payload', payload)

        var data = {
            bussines: sedes,
            ...payload,
            productos: _productos
        }
        console.log('data*************', data)
        const response = await axios({
            url: `/ms/carbone/v1.0/report/${payload.tipoReporte}`,
            method: 'POST',
            responseType: 'blob', // important
            headers: {
                /* "content-type":"application/octet-stream" */
            },
            data
        })
        //console.log('OUTPUT : ', response.data)
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        const timestamp = Date.now()
        const name = `caja_${bussines.shortName}_${payload.fechaConsulta}_${timestamp}`
        link.setAttribute('download', `${name}.pdf`)
        document.body.appendChild(link)
        link.click()
    }
}
const intToString = (val) => {
    //console.log('val ', val)
    if (typeof val == 'string') {
        return val
    } else {
        return JSON.stringify(val)
    }
}

const setList = (doc, lista, posX, posY, tipo) => {
    let y = posY
    const x = posX
    let subTotal = 0
    //console.log('tipo ', tipo)
    const _lista = Array.isArray(lista) ? lista : JSON.parse(lista)
    _lista.map((val) => {
        if (val) {
            y += 5
            //console.log('val ', val)
            doc.text(x, y, `${tipo == 1 ? val.quantity : val.unidades}`)
            doc.text(x + 6, y, `${val.description.slice(0, 18)}`)
            const result = val.subTotal / `${tipo == 1 ? val.quantity : val.unidades}`
            doc.text(x + 48, y, intToString(result.toFixed(2)), { align: 'right' })
            // doc.text(x + 48, y, intToString(val.price), { align: 'right' })
            doc.text(x + 59, y, intToString(val.subTotal), {
                align: 'right'
            })
            ////console.log('val.subTotal ', val.subTotal)
            subTotal += parseFloat(val.subTotal)
        }
    })
    return { y, subTotal }
}
const setList2 = (doc, lista, posX, posY, tipo) => {
    let y = posY
    const x = posX
    let subTotal = 0
    //console.log('tipo ', tipo)
    const _lista = Array.isArray(lista) ? lista : JSON.parse(lista)
    _lista.map((val) => {
        if (val) {
            y += 5
            //console.log('val ', val)
            //console.log('val.description.length', val.description.length)
            let i = 0
            const line = val.description.length / 36
            //console.log('line', line)
            let tinaLength = val.description.length / line
            let newSpace = 0
            let newSpace2 = tinaLength
            doc.text(x, y, `${tipo == 1 ? val.quantity : val.unidades}`)
            while (i < line) {
                //console.log('val i', i)
                //console.log('newSpace', parseInt(newSpace2))
                //console.log('tinaLength', parseInt(newSpace))
                doc.text(x + 12, y, `${val.description.slice(parseInt(newSpace), parseInt(newSpace2))}`)
                newSpace2 += tinaLength
                newSpace += tinaLength
                i++
                y += 3
            }

            ////console.log('val.subTotal ', val.subTotal)
            subTotal += parseFloat(val.subTotal)
        }
    })
    return { y, subTotal }
}
const setList3 = (doc, lista, posX, posY, tipo) => {
    let y = posY
    const x = posX
    let subTotal = 0
    //console.log('tipo ', tipo)
    const _lista = Array.isArray(lista) ? lista : JSON.parse(lista)
    _lista.map((val) => {
        if (val) {
            y += 5
            //console.log('element ', element)
            doc.text(x, y, `${val.description}`, { maxWidth: 50 })
            y += 12
            ////console.log('val.subTotal ', val.subTotal)
            subTotal += parseFloat(val.price)
        }
    })
    return { y, subTotal }
}
const renameList = (items, name) => {
    let values = []
    items.map((x) => {
        let importe = x.idTipoMoneda === 2 ? (x.tipoCambio ? x[name] * x.tipoCambio : '') : x[name]
        importe = formatNumber(importe, 1)
        return values.push({
            ...x,
            [name]: formatNumber(x[name], x.idTipoMoneda),
            importe
        })
    })
    return values
}

const getAllItems = (items, tipoMoneda) => {
    let result = []

    items.map((v) => {
        const productos = v.productos ? JSON.parse(v.productos) : []
        const privados = v.privados ? JSON.parse(v.privados) : []
        const masajes = v.masajes ? JSON.parse(v.masajes) : []
        const mixtos = v.mixtos ? JSON.parse(v.mixtos) : []
        let description = []
        let outTime
        let inTime
        let servicio = [privados, masajes, mixtos]
        for (const iterator of servicio) {
            if (iterator.length > 0) {
                //console.log('iterator[0].check_in_time', iterator[0].check_in_time)
                //console.log('iterator[0].check_out_time', iterator[0].check_out_time)
                let horaInicio = new Date(iterator[0].check_in_time)
                let horaFin = new Date(iterator[0].check_out_time)
                outTime = formatInTimeZone(horaFin, 'America/Lima', 'HH:mm:ss')
                inTime = formatInTimeZone(horaInicio, 'America/Lima', 'HH:mm:ss')
            }
        }

        productos.map((p) => description.push(p.description?.slice(0, 22)))
        privados.map((p) => description.push(p.description ? p.description.slice(0, 22) : ''))

        result.push({
            description: description.join(' , '),
            idTicket: v.id,
            tipoMoneda: getValue(v.idTipoMoneda, tipoMoneda, 'id', 'text'),
            Hour_Start: inTime ? inTime : null,
            Hour_End: outTime ? outTime : null,
            subTotal: v.importeTotal,
            importeAbonadoSoles: v.importeAcumuladoSoles,
            importeHoy: v.importeHoyAbonadoSoles
        })
        outTime = ''
        inTime = ''
    })
    return result
}
export default {
    namespaced: true,
    state,
    actions,
    mutations
}
