export class Ventas {
    constructor(payload) {
        return {
            idSede: payload.idSede || null,
            idBancoDestino: payload.idBancoDestino || null,
            idUserAssignment: payload.idUserAssignment || null,
            idColaborador: payload.idColaborador || null,
            idTipoDocumento: payload.idTipoDocumento || null,
            ubigeo: payload.ubigeo || null,
            numeroGuiaRemision: payload.numeroGuiaRemision || null,
            idTipoComprobante: payload.idTipoComprobante || null,
            numeroComprobante: payload.numeroComprobante || null,
            idTipoMoneda: payload.idTipoMoneda || null,
            idCotizacion: payload.idCotizacion || null,
            tipoCambio: payload.tipoCambio || null,
            numeroDocumento: payload.numeroDocumento || null,
            full_name: payload.full_name || null,
            tipoCliente: payload.tipoCliente || null,
            idCliente: payload.idCliente || null,
            check_in: payload.check_in || null,
            observation: payload.observation || null,
            importeTotal: payload.importeTotal || null,
            importeAbonado: payload.importeAbonado || null,
            tipoPago: payload.tipoPago || null,
            tipoPagoOrigen: payload.tipoPagoOrigen || null,
            efectivo: payload.efectivo || null,
            tarjeta: payload.tarjeta || null,
            transferencia: payload.transferencia || null,
            finalizado: payload.finalizado || null,
            fechaFacturacion: payload.fechaFacturacion || null,
            montoFacturacion: payload.montoFacturacion || null,
            direccionFacturacion: payload.direccionFacturacion || null,
            mixtos: payload.mixtos || null,

            privados: payload.privados || [],

            masajes: payload.masajes || [],

            productos: payload.productos || [],

            almacen: payload.almacen || [],
            adelanto: payload.adelanto || null,
            fecha_reservada: payload.fecha_reservada || null,
            sexo: payload.sexo || null,
            telefono: payload.telefono || null,
            correo: payload.correo || null,
            descuento: payload.descuento || null,
            id_reserva_origen: payload.id_reserva_origen || null,
            web_online: payload.web_online || null,
            created_at: payload.created_at || null,
            formaEntrega: payload.formaEntrega || null,
            agenciaEnvio: payload.agenciaEnvio || null,
            celularContacto: payload.celularContacto || null,
            direccion: payload.direccion || null,
            destinoFinal: payload.destinoFinal || null,
            referencia: payload.referencia || null,
            googleMaps: payload.googleMaps || null,
            montoDelivery: payload.montoDelivery || null,
            formaPago: payload.formaPago || null,
            bancoOrigen: payload.bancoOrigen || null,
            tipoEntrega: payload.tipoEntrega || null,
            pagos: payload.pagos || null,
            fechaCreacion: payload.fechaCreacion || null,
            status: payload.status || 0,
            numeroDocumento: payload.numeroDocumento || null,
            emailClient: payload.emailClient || null,
            phoneClient: payload.phoneClient || null
        }
    }
}
