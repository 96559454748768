<template>
    <v-row dense>
        <v-col cols="12" sm="7" md="8" order-xs-2 order-md1 class="mainLefLogin" />
        <v-col cols="12" sm="5" md="4" order-xs-1 order-md2 class="mainLogin">
            <v-row dense>
                <v-col cols="12" lg="10" offset-lg="1" xl="8" offset-xl="2" class="pa-3">
                    <form autocomplete="nope" @submit.prevent="goLogin">
                        <v-card flat>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="12" class="text-center">
                                        <img class="text-center" :src="loginImg" aspect-ratio="1" max-height="60" width="180px" />
                                        <h5 class="grey--text py-2">Bienvenido, por favor inicia sesión</h5>
                                    </v-col>
                                </v-row>
                                <v-row v-if="bussines.idBussines">
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="documento"
                                            v-validate="'required'"
                                            prepend-icon="mdi-account"
                                            outlined
                                            :error-messages="errors.collect('documento')"
                                            data-vv-name="documento"
                                            placeholder="47517690"
                                            required
                                            name="login"
                                            label="DOCUMENTO DE IDENTIDAD"
                                            type="text"
                                        />
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="password"
                                            v-validate="'required'"
                                            prepend-icon="mdi-lock"
                                            outlined
                                            name="password"
                                            label="Contraseña"
                                            placeholder="password"
                                            type="password"
                                            :error-messages="errors.collect('password')"
                                            data-vv-name="password"
                                        />
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn color="primary" block :loading="loading" type="submit">Ingresar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </form>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="12" class="text-center">
            <p style="font-size: 10px;">
                <strong class="caption">
                    Powered
                    <v-icon color="yellow">mdi-lightbulb-on-outline </v-icon><a href="https://innout.pe">innout.pe</a>
                    <strong> {{ versionNameDist }} </strong>
                </strong>
            </p>
        </v-col>
    </v-row>
</template>

<script>
import { mixins } from '@/mixins/mixin.js'
import { VersionAPP } from '../../utils/auth'
import { mapState, mapGetters } from 'vuex'

export default {
    mixins: [mixins],
    $_veeValidate: {
        validator: 'new'
    },
    data: () => ({
        documento: null,
        password: null,
        loading: false
    }),
    computed: {
        ...mapGetters('users', ['listOwnModules']),
        ...mapState('bussines', ['bussines']),
        versionNameDist() {
            return VersionAPP
        },
        loginImg() {
            const { idBussines } = this.bussines
            let defaultLogo = require(`@/assets/checkout.gif`)
            if (idBussines) {
                defaultLogo =
                    idBussines === 1
                        ? require(`@/assets/logo_kunte.jpg`)
                        : idBussines === 2
                        ? require(`@/assets/logo_cuba.jpg`)
                        : require(`@/assets/logo_kunteSAC.png`)
            }
            //console.log('result ', defaultLogo)
            return defaultLogo
        }
    },
    methods: {
        goLogin() {
            this.$validator.validateAll().then(async (result) => {
                try {
                    if (result) {
                        this.loading = true
                        const newUser = {
                            documento: this.documento,
                            password: this.password,
                            idBussines: this.bussines.idBussines
                        }

                        const { user, sede } = await this.$store.dispatch('users/validateLogin', {
                            data: newUser,
                            self: this
                        })

                        if (user) {
                            this.$store.commit('users/UPDATE_USER', user)
                            this.$store.commit('sedes/UPDATE_SEDE', sede)
                            ////console.log("ROLE: ", user);
                            const id_role = user.id_role
                            //console.log('id_role', id_role)
                            if ([2, 4].includes(user.idBussines) && id_role && id_role != 5) {
                                // this.$router.push('/app/reservas')
                                this.$router.push(this.listOwnModules[0].items[0].toPath)
                            } else if (user.idBussines === 1 && id_role && id_role != 5) {
                                // this.$router.push('/app')
                                this.$router.push(this.listOwnModules[0].items[0].toPath)
                            } else if (id_role === 5) {
                                //console.log('reparto')
                                this.$router.push(this.listOwnModules[0].items[0].toPath)
                                // this.$router.push('/logistica/reparto')
                            } else {
                                this.$store.commit('users/UPDATE_USER', {})
                                this.documento = null
                                this.password = null
                                this.loading = false
                                this.$router.push(this.listOwnModules[0].items[0].toPath)
                                // this.$router.push('/')
                            }
                            this.loading = false
                        } else {
                            //console.log('<--->, not found user')
                            this.$store.commit('mainUI/OPEN_BAR', {
                                state: true,
                                text: 'Usuario no existe',
                                color: 'grey'
                            })
                            this.documento = null
                            this.password = null
                            this.loading = false
                        }
                    }
                } catch (error) {
                    //console.log('<--->, not found user')
                    this.$store.commit('mainUI/OPEN_BAR', {
                        state: true,
                        text: 'Usuario no existe',
                        color: 'grey'
                    })
                    this.documento = null
                    this.password = null
                    this.loading = false
                }
            })
        }
    }
}
</script>
<style scoped>
.mainLefLogin {
    width: 100%;
    background: url('https://cubaspa.com.pe/wp-content/uploads/2022/03/PISCO.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}
.mainLogin {
    display: flex;
    background: #f4f4f4;
    justify-content: center;
    align-items: center;
    height: 95vh;
    width: 100%;
}
</style>
