import store from '../store'
import printJS from 'print-js'

export const formatNumber = (val, idTipoMoneda) => {
    const dfidTipoMoneda = idTipoMoneda || 1
    const isFind = store.state.utilities.tipoMoneda.find((x) => x.id == dfidTipoMoneda)
    return new Intl.NumberFormat(isFind.country.toString(), { style: 'currency', currency: isFind.currency.toString() }).format(Number(val))
}
export const financial = (x) => {
    const r = Number.parseFloat(x || 0).toFixed(2)
    return r.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
export const sortItems = (items, label, tipo, kind = 1) => {
    if (kind === 1) {
        if (tipo === 1) {
            //Descendente
            return items.sort((a, b) => b[label] - a[label])
        } else {
            return items.sort((a, b) => a[label] - b[label])
        }
    } else {
        // by date
        if (tipo === 1) {
            //Descendente
            return items.sort((a, b) => (a[label] < b[label] ? -1 : a[label] > b[label] ? 1 : 0))
        } else {
            return items.sort((a, b) => (a[label] > b[label] ? -1 : a[label] < b[label] ? 1 : 0))
        }
    }
}

export const getValue = (idValue, items, label, labelReturn) => {
    let output = ''
    try {
        // //console.log('idValue ', idValue, ' items ', items)
        const result = items.find((x) => x[label] == idValue)
        output = result[labelReturn]
    } catch (error) {
        ////console.log('err ', error)
    }
    return output
}

export const callAsync = async (url, method, payload) => {
    try {
        const { data } = await axios({
            url,
            method,
            headers: {
                'content-type': 'application/json'
            },
            data: payload
        })

        //console.log('DATA---->', data)
        return data
    } catch (error) {
        //console.log('err ', error)
        throw new Error('failed function')
    }
}

export const downloadFile = async (payload, name, type, isPrint = false) => {
    const timestamp = Date.now()
    const rename = `${name}_${timestamp}`
    const blobFile = new Blob([payload], { type: `application/${type}` })
    const url = window.URL.createObjectURL(blobFile)
    const link = document.createElement('a')
    link.style = 'display: none'
    document.body.appendChild(link)
    link.href = url
    link.target = '_blank'
    if (type === 'pdf' && isPrint) {
        printJS(url)
    }
    link.setAttribute('download', `${rename}.${type}`)
    link.click()
    window.URL.revokeObjectURL(url)
    link.remove()

    const base64 = await blobToBase64(blobFile)
    return {
        filename: `${rename}.${type}`,
        path: base64
    }
}

export const ticketPDF = async (payload, name, type) => {
    const timestamp = Date.now()
    const rename = `${name}_${timestamp}`
    const blobFile = new Blob([payload], { type: `application/${type}` })
    const url = window.URL.createObjectURL(blobFile)
    const link = document.createElement('a')
    link.style = 'display: none'
    document.body.appendChild(link)
    link.href = url
    link.target = '_blank'
    if (type === 'pdf' && isPrint) {
        printJS(url)
    }
    // link.setAttribute('download', `${rename}.${type}`)
    // link.click()
    // window.URL.revokeObjectURL(url)
    // link.remove()

    const base64 = await blobToBase64(blobFile)
    return {
        filename: `${rename}.${type}`,
        path: base64
    }
}
function quitarComillas(cadena) {
    return cadena.replace(/["']/g, '')
}

export const sanitizeData =  (objeto) => {
    if (typeof objeto !== 'object' || objeto === null) {
      return objeto;
    }
    for (const clave in objeto) {
      if (typeof objeto[clave] === 'string') {
        // Si el valor de la propiedad es una cadena de texto, quitar las comillas.
        objeto[clave] = objeto[clave].replace(/["']/g, '');
      } else if (typeof objeto[clave] === 'object') {
        // Si el valor de la propiedad es otro objeto, llamar a la función recursivamente.
        objeto[clave] =  sanitizeData(objeto[clave]);
      }
    }

    return objeto;
}
export const setParseJson = (payload) => {
    try {
        return JSON.parse(payload)
    } catch (error) {
        if (payload) {
            return payload
        } else {
            return []
        }
    }
}
export const blobToBase64 = (blob) => {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    return new Promise((resolve) => {
        reader.onloadend = () => {
            resolve(reader.result)
        }
    })
}

export function exportXLSX({ headers, campos, arrayData }, { filename, autoWidth, bookType }) {
    const formatJson = (filterVal, jsonData) => {
        console.log('jsonData', jsonData)
        return jsonData.map((v) =>
            filterVal.map((j) => {
                return v[j]
            })
        )
    }

    const _autoWidth = autoWidth || true
    const _bookType = bookType || 'xlsx'

    import('@/vendor/Export2Excel').then((excel) => {
        const data = formatJson(campos, arrayData)
        excel.export_json_to_excel({
            header: headers,
            data,
            filename: filename,
            autoWidth: _autoWidth,
            bookType: _bookType
        })
    })
}
export const stringToNull = (str) => {
    if (str == '' || str == null || str == undefined) {
        return null
    } else {
        return str
    }
}

export const sleep = async (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms))
}
export const changeSoles = (array, key, tc) => {
    const lista = []
    const items = {}
    console.log('array', array)
    array.map((x) => {
        if (x.idTipoMoneda == 2) {
            key.map((y) => {
                items[y] = (x[y] * tc).toFixed(2)
            })
        }
        lista.push({
            ...x,
            idTipoMoneda: 1,
            ...items
        })
    })
    return lista
}

export const splitfirsLetter = (p) => {
    const isSpace = p.indexOf(' ')
    if (isSpace > 0) {
        return p.slice(0, isSpace + 1)
    } else {
        return p
    }
}
