import request from '@/utils/request'

const state = {
    bussines: {
        idBussines: 0,
        nombres: null,
        apellidos: null,
        correo: null,
        password: null,
        role: 0,
        caja_open: false,
        id: null,
        idSede: null
    },
    listaBussines: []
}

const getters = {}

const mutations = {
    UPDATE_BUSSINES(state, payload) {
        state.bussines = payload
    },
    ALL_BUSSINES(state, payload) {
        state.listaBussines = payload
    }
}

const actions = {
    async getBussines({ commit, state }, domain) {
        const query = encodeURIComponent(`{"where":{"and":[{"url":${domain}}]}}`)
        if (!state.bussines.idBussines) {
            const response = await request({
                url: `/api-loopback/tb_bussines?filter=${query}`,
                method: 'GET',
                headers: {
                    'content-type': 'application/json'
                }
            })
            //console.log('bussines_from_server', response.data)
            // ////console.log(response.data);
            commit('UPDATE_BUSSINES', {
                ...response.data[0],
                idBussines: response.data[0].id
            })
        } else {
            //console.log('bussines_from_cache')
        }
    },
    async getAllBussines({ commit, state }, domain) {
        if (state.listaBussines.length < 1) {
            const { data } = await request({
                url: `/api-loopback/tb_bussines`,
                method: 'GET',
                headers: {
                    'content-type': 'application/json'
                }
            })
            //console.log('ALL_bussines_from_server', data)
            // ////console.log(response.data);
            commit('ALL_BUSSINES', data)
        } else {
            //console.log('ALL_bussines_from_cache')
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
