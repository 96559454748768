import request from '@/utils/request'

const state = {
    listaPrecio: [],
    listaHab: [],
    listService: []
}

const getters = {
    listHabAvailable(state) {
        const items = []
        state.listaPrecio.map((val) => {
            const priv = state.listaHab.find((el) => el.id == val.id_hab_sede)
            items.push({ ...val, room: priv.room })
        })
        return items
    },
    listaHab(state) {
        return state.listaHab
    }
}

const mutations = {
    UPDATE_LISTA_PRECIO(state, payload) {
        state.listaPrecio = payload
    },
    UPDATE_LISTA(state, payload) {
        state.listaHab = payload
    },
    UPDATE_LIST_SERVICE(state, payload) {
        state.listService = payload
    }
}

const actions = {
    async servicios({ rootState, commit, state }, payload) {
        const active = payload['active']
        if (state.listService.length < 1 || active === 0) {
            const { idSede, idBussines } = rootState.users.user
            //console.log('rootState.users', rootState.users)
            const { data } = await request({
                url: `/ms/migraciones/v1.0/products?typeProduct=2&idSede=${idSede}&idBussines=${idBussines}`,
                method: 'GET',
                headers: {
                    'content-type': 'application/json'
                }
            })
            //console.log('data', data)
            commit('UPDATE_LIST_SERVICE', data.data)
        } else {
            //console.log('get privado from cache')
        }
    },
    async getTable({ commit, rootState, state }, payload) {
        const { idSede } = rootState.users.user
        if (state.listaHab.length < 1 || payload) {
            //console.log('privados_from_server')

            const response = await request({
                url: `/api-loopback/tb_hab_sedes?filter={"where":{"and":[{"id_sede_room": ${idSede}}]}}`,
                method: 'GET',
                headers: {
                    'content-type': 'application/json'
                }
            })

            // //console.log(response.data);
            commit('UPDATE_LISTA', response.data)
        } else {
            //console.log('privados_from_cache')
        }
    },
    async getLista({ commit, rootState, state }, payload) {
        const { idSede } = rootState.users.user
        if (state.listaPrecio.length < 1 || payload) {
            //console.log('list_privados_from_server')
            const response = await request({
                /* url: `/api-loopback/tb_price_habs/sede/${idSede}`, */
                url: `/api-loopback/tb_price_habs?filter={"where":{"and":[{"idSede": ${idSede}}]}}`,
                method: 'GET',
                headers: {
                    'content-type': 'application/json'
                }
            })
            // //console.log("lista");
            // //console.log(response.data);
            commit('UPDATE_LISTA_PRECIO', response.data)
        } else {
            //console.log('list_privados_from_cache')
        }
    },
    async postLista({ rootState }, payload) {
        const data = {
            idColaborador: rootState.users.user.id,
            id_hab_sede: payload.id_hab_sede,
            idSede: rootState.users.user.idSede,
            description: payload.description,
            hours: payload.hours,
            price: payload.price,
            title: payload.title,
            content: payload.content,
            img_url: payload.img_url,
            keywords: payload.tags,
            tags: payload.tags,
            web_online: payload.web_online
        }

        //console.log('POST PRICE', data)
        const response = await request({
            url: '/api-loopback/tb_price_habs',

            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            data
        })
        // ////console.log(response.data);
        return response.data
    },
    async delLista({}, payload) {
        // //console.log(payload);
        const response = await request({
            url: `/api-loopback/tb_price_habs/${payload.id}`,

            method: 'DELETE',
            headers: {
                'content-type': 'application/json'
            }
        })
        // //////console.log(response.data);
        return response.data
    },
    async updatePrivado({}, payload) {
        // ////console.log(payload);
        const data = { ...payload, keywords: payload.tags }
        const response = await request({
            url: `/api-loopback/tb_price_habs/${payload.id}`,
            method: 'PUT',
            headers: {
                'content-type': 'application/json'
            },
            data
        })
        // //////console.log(response.data);
        return response.data
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
