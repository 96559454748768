import { format, subDays, subMonths, addMonths } from 'date-fns'
import DefaultForm from '@/utils/defaultForm'
import { formatInTimeZone } from 'date-fns-tz'
import { sortItems, downloadFile } from '@/utils/genericUtils'
import { Ventas } from '../..//models/venta'
import request from '@/utils/request'

/* ## callback venta ## */
const state = {
    datos: DefaultForm.formDefault(),
    modalReserva: {
        step: 1, // default 1
        state: false, // false Default,
        showItem: false
    },
    listCodeBar: {},
    mixtos: [],
    privados: [],
    masajes: [],
    dataVenta: [],
    productos: [],
    idVenta: {},
    servicios: [],
    cortesia: [],
    mixtosToday: [],
    privadosToday: [],
    productosToday: [],
    masajesToday: [],
    loadingReservas: false,
    listaVentas: [],
    listaVentaHisto: [],
    listaCalendarioToday: []
}

const getters = {
    getListaVentas: (state) => {
        return sortItems(state.listaVentas, 'id', 1)
    },
    // items: [
    //     { id: 12, text: 'Privado' },
    //     { id: 11, text: 'Masaje' },
    //     { id: 10, text: 'Mixto' }
    // ],
    listaItemMixto: (state) => {
        let lista = []
        state.listaCalendarioToday.map((x) => {
            const newList = x.privados ? x.privados : []

            const item = newList.filter((y) => y.category_id == 10)
            lista = lista.concat(item)
        })
        return lista
    },
    listaItemMasajes: (state) => {
        let lista = []
        state.listaCalendarioToday.map((x) => {
            const newList = x.privados ? x.privados : []

            const item = newList.filter((y) => y.category_id == 11)
            lista = lista.concat(item)
        })
        return lista
    },
    listaItemPrivados: (state) => {
        let lista = []
        state.listaCalendarioToday.map((x) => {
            const newList = x.privados ? x.privados : []

            const item = newList.filter((y) => y.category_id == 12)
            lista = lista.concat(item)
        })
        return lista
    },
    getListaVentaToday: (state) => {
        const today = format(new Date(), 'yyyy-MM-dd')
        return state.listaVentaHisto.filter((x) => x.fechaCreacion == today)
    },

    listServices(state) {
        return state.servicios
    }
}

const mutations = {
    UPDATE_LISTA(state, payload) {
        state.listaVentas = payload
    },

    UPDATE_MYCODEBAR(state, payload) {
        state.listCodeBar = payload
    },
    UPDATE_DATAVENTA(state, payload) {
        state.dataVenta = payload
    },

    UPDATE_MIXTOS(state, payload) {
        state.mixtos = payload
    },
    UPDATE_CORTESIA(state, payload) {
        state.cortesia = payload
    },
    UPDATE_IDVENTA(state, payload) {
        state.idVenta = payload
    },
    UPDATE_PRIVADOS(state, payload) {
        state.privados = payload
    },
    UPDATE_PRODUCTOS(state, payload) {
        state.productos = payload
    },
    UPDATE_MASAJES(state, payload) {
        state.masajes = payload
    },
    UPDATE_MIXTOS_TODAY(state, payload) {
        state.mixtosToday = payload
    },
    UPDATE_PRIVADOS_TODAY(state, payload) {
        state.privadosToday = payload
    },
    UPDATE_PRODUCTOS_TODAY(state, payload) {
        state.productosToday = payload
    },
    UPDATE_MASAJES_TODAY(state, payload) {
        state.masajesToday = payload
    },
    SHOW_MODAL(state, payload) {
        state.modalReserva = payload
    },
    UPDATE_DATOS(state, payload) {
        console.log('atributos___update ', payload)
        state.datos = { ...state.datos, ...payload }
    },
    UPDATE_LISTA_RESERVAS(state, payload) {
        state.listaVentaHisto = payload
    },
    UPDATE_LISTA_RESERVAS_TODAY(state, payload) {
        state.listaPreReservaToday = payload
    },

    UPDATE_LISTA_RESERVAS_PENDIENTES(state, payload) {
        state.listaCalendarioToday = payload
    },
    UPDATE_LOADING_RESERVAS(state, payload) {
        state.loadingReservas = payload
    },
    RESET_DATOS(state) {
        state.datos = DefaultForm.formDefault()
        state.mixtos = []
        state.privados = []
        state.masajes = []
        state.productos = []
        state.cortesia = []
    }
}

const actions = {
    async lsVentaNoFinalizada({ commit, rootState }) {
        const check_in = format(new Date(), 'yyyy-MM-dd')
        const { idSede } = rootState.users.user
        const today = JSON.stringify(format(new Date(), 'yyyy-MM-dd'))

        const query = encodeURIComponent(`{"where":{"and":[{"idSede": ${idSede}},{"check_in": ${today}},{"finalizado": 0},{"status": 1}]}}`)
        //console.time('lsVentaNoFinalizada')
        const { data: response } = await request({
            url: `/api-loopback/tm_ventas/getVentasByDate/${idSede}/${check_in}/0/1`,
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            }
        })

        //console.timeEnd('lsVentaNoFinalizada')

        //console.log('result---> ', response)
        const listaMixtos = []
        const listaPrivados = []
        const listaMasajes = []
        const listaProductos = []

        response.map((item) => {
            // //////console.log("here-item")
            // //////console.log(item)
            const mixtos = item.mixtos ? JSON.parse(item.mixtos) : []
            const privados = item.privados ? JSON.parse(item.privados) : []
            const masajes = item.masajes ? JSON.parse(item.masajes) : []
            const productos = item.productos ? JSON.parse(item.productos) : []

            const defaultService = {
                id_reserva: item.id,
                idTipoVenta: item.idTipoVenta,
                full_name: item.full_name
            }

            mixtos.map((val) =>
                listaMixtos.push({
                    ...val,
                    ...defaultService
                })
            )
            privados.map((val) =>
                listaPrivados.push({
                    ...val,
                    ...defaultService
                })
            )
            masajes.map((val) =>
                listaMasajes.push({
                    ...val,
                    ...defaultService
                })
            )
            productos.map((val) =>
                listaProductos.push({
                    ...val,
                    ...val,
                    ...defaultService
                })
            )
        })
        commit('UPDATE_MIXTOS_TODAY', listaMixtos)
        commit('UPDATE_PRIVADOS_TODAY', listaPrivados)
        commit('UPDATE_MASAJES_TODAY', listaMasajes)
        commit('UPDATE_PRODUCTOS_TODAY', listaProductos)
    },
    async postInitVenta({ state, commit, rootState }, payload) {
        const { idSede, idBussines, id } = rootState.users.user
        console.log('rootState.users.user------------------', rootState.users.user)
        const data = new Ventas(state.datos)
        console.log('REQ', data)
        const response = await request({
            url: `/api-loopback/tm_ventas`,
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            data: {
                ...data,
                idSede,
                idEstado: 33, //new
                idBussines,
                idUserAssignment: data.idUserAssignment ? data.idUserAssignment : id
            }
        })
        //console.log('creating_basic_venta_', response.data)
        commit('UPDATE_DATOS', { ...response.data })
    },

    async botWhatsapp({ state, commit, rootState }, payload) {
        const { data } = await request({
            url: 'https://ms.prod.innout.cloud/api-orquestador/call/v1.0/bot',
            method: 'post',
            headers: {
                'content-type': 'application/json'
            },
            data: payload
        })
        console.log('data--', data)
    },
    async patchVenta({ state, commit, rootState }, payload) {
        await request({
            url: `/api-loopback/tm_ventas/${payload.id}`,
            method: 'PATCH',
            headers: {
                'content-type': 'application/json'
            },
            data: payload
        })
        //console.log("data---",data)
    },
    async CrearCitaOrdenCompra({ state, commit, rootState }, payload) {
        console.log('paylaod--', payload)
        await request({
            url: '/api-orquestador/v2.0/cron/generarCita',
            //url: 'http://localhost:3000/orq/v2.0/cron/generarCita',
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            data: payload
        })
    },
    async enviarComprobante({ commit }, payload) {
        const { clientEmail, fechaFacturacion, isEmailSelected, _full_name, documento } = payload
        const comprobante = documento.find((v) => v.id == isEmailSelected)
        const { fileName, url } = comprobante
        if (clientEmail) {
            const { data } = await request({
                url: '/api-orquestador/email/v2.0/Comprobante',
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                data: {
                    email: clientEmail,
                    asunto: 'ENVIO DEL COMPROBANTE',
                    parametros: {
                        Tipo_Envio: isEmailSelected,
                        full_name: _full_name,
                        serviceId: 3,
                        archivosAdjuntos: '',
                        fechaCreacion: fechaFacturacion,
                        url: url,
                        fileName: `${fileName}.pdf`
                    }
                }
            })
            console.log('response', data)
            return data
        }
    },

    async comprobante({ state, commit, rootState }, data) {
        console.log('data', data)
        const response = await request({
            //url: `http://localhost:3000/ms/migraciones/v1.0/comprobante/emitir`,
            url: `/ms/migraciones/v1.0/comprobante/emitir`,
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            data
        })
        console.log('response', response.data)
        return response
    },
    async obtenerVenta({ state, commit, rootState }, payload) {
        //console.log('result', payload)

        const { data } = await request({
            url: `/ms/inventario/private/1.0/obtener/venta`,
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            data: {
                idVenta: Number(payload.id)
            }
        })
        //console.log('creating_basic_venta_', data)
        return data.data
    },

    async obtenerProducto({ state, commit, rootState }, payload) {
        // console.log('result obtenerProducto', payload)

        const { data } = await request({
            url: `/ms/migraciones/v1.0/items?id=${payload.id}&idSede=${payload.idSede}`,
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            }
        })
        //console.log('obtenerProducto', data)
        return data
    },
    async ventaProducto({ state, commit, rootState }, payload) {
        const { data } = await request({
            url: `api-loopback/tm_ventas/${payload.id}`,
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            }
        })
        //console.log('ventaProducto', data)
        return data
    },
    async crearOrdenPedido({ state, commit, rootState }, payload) {
        //console.log('result obtenerProducto', payload)
        const { vendedor, personalCargo, fechaEmision, ordenPedido, producto, color, medida, material, observaciones } = payload

        const { data } = await request({
            url: `/ms/inventario/private/1.0/agregar/orden/Produccion`,
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            data: {
                vendedor,
                personalCargo,
                fechaEmision,
                ordenPedido: ordenPedido ? ordenPedido : null,
                producto,
                color: color ? color : 'NO DEFINIDO',
                medida: medida ? medida : 'NO DEFINIDO',
                material,
                observaciones
            }
        })
        //console.log('creating_basic_venta_', data)
        return data
    },
    async crearOrdenEquipamiento({ state, commit, rootState }, payload) {
        //console.log('result obtenerProductoOE', payload)
        const { vendedor, personalCargo, fechaEmision, ordenPedido, producto, model, medida, color, posicion, observaciones, items } = payload
        const { data } = await request({
            url: `/ms/inventario/private/1.0/agregar/orden/Equipamiento`,
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            data: {
                vendedor,
                personalCargo,
                fechaEmision,
                ordenPedido: ordenPedido ? ordenPedido : 0,
                producto,
                modelo: model ? model : 'NO DEFINIDO',
                medida: medida ? medida : 'NO DEFINIDO',
                color: color ? color : 'NO DEFINIDO',
                posicion,
                observaciones,
                items
            }
        })
        //console.log('creating_basic_venta_', data)
        return data
    },
    async postVenta({ state, rootState }, payload) {
        // //console.log("POST RESERVAS");
        const { mixtos, privados, masajes, productos, cortesia } = state
        //const importeTotal = GET_IMPORTE_TOTAL(mixtos, privados, masajes, productos);
        const data = {
            ...state.datos,
            idSede: rootState.users.user.idSede,
            idColaborador: rootState.users.user.id,
            finalizado: payload.estado,
            idEstado: payload.estado ? 34 : 33,
            mixtos,
            privados,
            masajes,
            productos,
            cortesia
        }
        //33 -- nuevo
        //34 -- finalizado
        //console.log('INPUT : ', data)
        const response = await request({
            url: `/api-orquestador/ventas/v2.0/kunte`,
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            data
        })
        //console.log('output: ', response.data)
        return response.data
        // //////console.log(response.data)
    },
    async obtenerDataVenta({ state, rootState }, payload) {
        const response = await request({
            url: `/api-orquestador/ventas/v2.0/kunte`,
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            data
        })
        //console.log('output: ', response.data)
        return response.data
    },
    async obtenerDataVenta({ state, rootState }, payload) {},
    async deleteVenta({}, payload) {
        /*     //console.log(payload.idTipoVenta) */
        const { idTipoVenta } = payload
        /* //console.log(api) */
        const response = await request({
            url: `/api-loopback/tm_ventas/${payload.id}`,

            method: 'DELETE',
            headers: {
                'content-type': 'application/json'
            }
        })
        //console.log(response.data)
        return response.data
    },
    async assignmentVenta({}, payload) {
        console.log('payload', payload)
        await request({
            url: `/api-loopback/tm_ventas/${payload.idVenta}`,
            method: 'PATCH',
            headers: {
                'content-type': 'application/json'
            },
            data: {
                idUserAssignment: payload.idUsuario,
                idSede: payload.data.idSede
            }
        })
    },
    async selectReserva({ commit, rootGetters }, payload) {
        //...mapGetters('clientes', ['listaClientes']),
        console.log('result ventaProducto', payload)

        const listaClientes = rootGetters['clientes/listaClientes']
        console.log('payload_edit', payload)
        //console.log(`id: ${payload.id}`)
        const response = await request({
            url: `/api-loopback/tm_ventas/${payload.id}`,

            method: 'GET',
            headers: {
                'content-type': 'application/json'
            }
        })
        const item = response.data
        console.log('item ', item)
        const findCliente = listaClientes.find((x) => x.id === item.idCliente)
        console.log('------>findCliente', findCliente)

        let _item = {
            ...item,
            atributos: item.atributos ? item.atributos : DefaultForm.formAttributes(),
            numeroDocumento: findCliente ? findCliente.number : null,
            emailClient: findCliente ? findCliente.email : null,
            phoneClient: findCliente ? findCliente.telephone : null,
            full_name: findCliente ? findCliente.defaultName : null,
            idTipoDocumento: findCliente ? findCliente.identity_document_type_id : null,
            formaPago: Number(item.formaPago)
        }
        console.log('items', _item)

        commit('RESET_DATOS')
        commit('UPDATE_IDVENTA', payload.id)
        commit('UPDATE_MIXTOS', item.mixtos)
        commit('UPDATE_PRIVADOS', item.privados)
        commit('UPDATE_MASAJES', item.masajes)
        commit('UPDATE_PRODUCTOS', item.productos)
        commit('UPDATE_DATOS', _item)
    },
    // async getLastCodeBar({ commit }, payload) {
    //     const { data } = await request({
    //         url: `/ms/inventario/private/1.0/get/barcode`,
    //         method: 'POST',
    //         headers: {
    //             'content-type': 'application/json'
    //         },
    //         data: {
    //             idVenta: payload.id
    //         }
    //     })
    //     // //console.log("get_reserva");
    //     commit('UPDATE_MYCODEBAR', data)
    // },
    async activarTicket({ commit }, payload) {
        //console.log('payload', payload)
        const { data } = await request({
            url: `/api-loopback/tm_ventas/${payload.id}`,
            method: 'PATCH',
            headers: {
                'content-type': 'application/json'
            },
            data: {
                finalizado: 0
            }
        })
    },
    async updateReserva({ rootState, dispatch, commit, state }, payload) {
        // //console.log("UPDATE_RESERVAS");
        const { mixtos, privados, masajes, productos, cortesia, datos } = state
        const { idTipoVenta, efectivo, transferencia, tarjeta } = datos
        // //console.log('idTipoVenta ', idTipoVenta)
        const importeTotal = GET_IMPORTE_TOTAL(mixtos, privados, masajes, productos)
        const importeAbonado = SUMA_TOTAL(efectivo, tarjeta, transferencia)
        console.log('payload', payload)
        let data = {
            ...datos,
            status: 1,
            importeTotal,
            importeAbonado,
            mixtos: state.mixtos,
            privados: state.privados,
            masajes: state.masajes,
            productos: state.productos,
            id_reserva: payload.id,
            finalizado: payload.estado,
            atributos: {
                ...datos.atributos
            }
            // nroOperacion:payload.
        }
        // //console.log("body");
        if (payload.estado == 1 && datos.idBussines == 1) {
            data.idEstado = 34
        } else {
            delete data.idEstado
        }

        console.log('data---', data)
        /* updateReserva */
        const response = await request({
            url: `/api-loopback/tm_ventas/${payload.id}`,
            method: 'PATCH',
            headers: {
                'content-type': 'application/json'
            },
            data
        })
        console.log('response.data ', response.data)
        commit('UPDATE_DATOS', { ...response.data })
        return response.data
    },
    async getReservas({ commit, rootState }, { firstDay, nextDay, tipoFiltro }) {
        //console.log('getReservas')
        //console.log('firstDay', firstDay)
        //console.log('nextDay', nextDay)
        commit('UPDATE_LOADING_RESERVAS', true)
        const { idSede, id_role, idBussines } = rootState.users.user
        const _nextDay = nextDay || formatInTimeZone(addMonths(new Date(), 1), 'America/Lima', 'yyyy-MM-dd')
        const _firstDay = firstDay || formatInTimeZone(subMonths(new Date(), 2), 'America/Lima', 'yyyy-MM-dd')
        //console.log('_nextDay', _nextDay)
        //console.log('_firstDay', _firstDay)
        const _tipoFiltro = [2, 3, 4].includes(id_role) ? 5 : tipoFiltro || 3
        //console.log('getReservas2')
        //{"idSede":"5","_firstDay":"2022-04-18","_nextDay":"2022-07-18","_tipoFiltro":"5","idBussines":2} -- diego
        //{"idSede":"5","_firstDay":"2022-04-18","_nextDay":"2022-07-18","_tipoFiltro":"5","idBussines":2}
        const { data: result } = await request({
            url: `/ms/migraciones/v2.0/ventas`,
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            data: {
                idSede: String(idSede),
                _firstDay: String(_firstDay),
                _nextDay: String(_nextDay),
                _tipoFiltro: String(_tipoFiltro),
                idBussines: Number(idBussines)
            }
        })

        //console.log('getReservas', result.data)
        let items = result.data
        console.log('items V1', items)
        console.log('id_role ', id_role)
        if (id_role == 4) {
            items = result.data.filter((x) => {
                if (x['idEstado'] != 9 && x['idEstado'] != 11 && x.finalizado != 1) {
                    return x
                }
            })
        }
        console.log('items V2', items)
        commit('UPDATE_LISTA_RESERVAS', items)
        commit('UPDATE_LOADING_RESERVAS', false)
    },
    async getReservasToday({ commit, rootState }, payload) {
        const { idSede } = rootState.users.user
        const { today } = payload
        const query = encodeURIComponent(`{"where":{"and":[{"idSede": ${idSede}},{"check_in": ${today}}]}}`)
        //console.log('query')
        //console.log(query)
        const response = await request({
            url: `/api-loopback/tm_ventas?filter=${query}`,
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            }
        })
        //console.log(response.data)
        commit('UPDATE_LISTA_RESERVAS_TODAY', response.data)
        return response.data
    },
    async getPendientesReservas({ commit, rootState }) {
        const { idSede } = rootState.users.user
        const today = JSON.stringify(format(subDays(new Date(), 1), 'yyyy-MM-dd'))
        const query = encodeURIComponent(`{"where":{"and":[{"idSede": ${idSede}},{"check_in": {"gt":${today}}}]}}`)

        //console.log('query')
        //console.log(query)
        const response = await request({
            url: `/api-loopback/tm_ventas?filter=${query}`,
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            }
        })
        //console.log('getPendientesReservas', response.data)
        let items = []
        response.data.map((x) => items.push({ ...x, importePendiente: x.importeTotal - x.adelanto }))
        commit('UPDATE_LISTA_RESERVAS_PENDIENTES', items)
        return items
    },
    async traerOrdenesEquipamiento({ commit, rootState }, payload) {
        //console.log('payloadequipamiento', payload)
        let ordenEquipamiento = []
        const { data } = await request({
            url: `/ms/inventario/private/1.0/traer/orden/equipamiento`,
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            data: {
                id: payload.id
            }
        })
        const idVenta = data.data.find((x) => x.ordenPedido).ordenPedido
        const response = await request({
            url: `/api-loopback/tm_ventas/${idVenta}`,
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            }
        })
        let prods = []
        for (let i = 1; i < response.data.productos.length; i++) {
            prods.push(response.data.productos[i])
        }
        data.data.forEach((x) => {
            ordenEquipamiento.push({ ...x, items: prods })
        })
        return ordenEquipamiento
    },
    async delReserva({}, payload) {
        // ////console.log(payload);
        const response = await request({
            url: `/api-loopback/tm_ventas/${payload.id}`,

            method: 'DELETE',
            headers: {
                'content-type': 'application/json'
            }
        })
        // //////console.log(response.data);
        return response.data
    },
    async getVentasByMes({ commit, rootState }, payload) {
        const { data } = await request({
            url: `/api-loopback/tm_ventas/getVentasByMes/${payload.idSede}/${payload.fecha}`,
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            }
        })

        //console.log('PRE-RESERVAS----->', data)
        return data

        //commit('UPDATE_LISTA_RESERVAS', result)
    },
    async getVentasByMonth({ commit, rootState }, payload) {
        const { date, idColaborador } = payload
        let fechaInicial, fechaFinal
        console.log('date', date)
        console.log('length', date.length)
        if (date.length === 7) {
            // Si la variable es un mes
            fechaInicial = `${date}-01T00:00:00.000`
            fechaFinal = `${date}-31T23:59:59.999`
        } else {
            // Si la variable es una fecha completa
            fechaInicial = `${date}T00:00:00.000`
            fechaFinal = `${date}T23:59:59.999`
        }

        console.log('fechaFinal', fechaFinal)
        const filter = {
            where: {
                and: [
                    {
                        created_at: { between: [fechaInicial, fechaFinal] }
                    },
                    {
                        idColaborador: idColaborador
                    },
                    {
                        status: 1
                    },
                    {
                        idEstado: { neq: 11 }
                    }
                ]
            }
        }
        console.log('url', `/api-loopback/tm_ventas?filter=${JSON.stringify(filter)}`)

        const { data } = await request({
            url: `/api-loopback/tm_ventas?filter=${JSON.stringify(filter)}`,
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            }
        })
        return data
    }
}

const getSubTotal = (item) => {
    try {
        if (item.length > 0) {
            let suma = 0
            item.map((val) => {
                suma += parseFloat(val.subTotal)
            })
            return suma
        }
        return 0
    } catch (error) {
        return 0
    }
}

const GET_IMPORTE_TOTAL = (a, b, c, d) => {
    return getSubTotal(a) + getSubTotal(b) + getSubTotal(c) + getSubTotal(d)
}

const SUMA_TOTAL = (a, b, c) => {
    return parseFloat(a) + parseFloat(b) + parseFloat(c)
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
