import request from '@/utils/request'
const state = {
    listaProductos: [],
    listaProductosOut: [],
    servicios: [],
    dataproducto: []
}

const getters = {
    customProducts(state, getters, rootState) {
        let items = []

        state.dataproducto.map((v) => {
            const category = rootState.category.genericUtils.find((x) => x.id === v.id_category) || ''
            items.push({
                ...v,
                category: category.name,
                comision: 0,
                descuentos: 0,
                unidades: 1,
                rename: `${v.description} -|- ${v.marca} -|- ${v.internal_id}`
            })
        })

        return items
    },
    customServices(state, getters, rootState) {
        let items = []
        console.log('state.servicios', state.servicios)

        state.servicios.map((v) => {
            if (v.line && v.line.toUpperCase().includes('VENTA')) {
                const category = rootState.category.genericUtils.find((x) => x.id === v.id_category) || ''
                items.push({
                    ...v,
                    category: category.name,
                    comision: 0,
                    descuentos: 0,
                    unidades: 1,
                    rename: `${v.description} -|- ${v.marca} -|- ${v.internal_id}`
                })
            }
        })

        return items
    },

    listaProductosAvailables(state) {
        return state.listaProductos.filter((val) => {
            if (val.id_category != '4' && val.id_category != '5') {
                return val
            }
        })
    },
    listaCortesia(state) {
        return state.listaProductos.filter((val) => {
            if (val.id_category == '4' || val.id_category == '5') {
                return val
            }
        })
    },
    listaProductosOut(state) {
        return state.listaProductosOut.filter((val) => {
            if (val.id_category != '4' && val.id_category != '5') {
                return val
            }
        })
    },
    listaCortesiaOut(state) {
        return state.listaProductos.filter((val) => {
            if (val.id_category == '4' || val.id_category == '5') {
                return val
            }
        })
    }
}

const mutations = {
    UPDATE_LISTA(state, payload) {
        state.listaProductos = payload
    },
    UPDATE_LISTA_OUT(state, payload) {
        state.listaProductosOut = payload
    },
    UPDATE_LISTA_SERVICIOS(state, payload) {
        state.servicios = payload
    },

    UPDATE_DATAPRODUCTO(state, payload) {
        state.dataproducto = payload
    }
}

const actions = {
    async products({ rootState, commit, state }, payload) {
        const active = payload['active']
        //console.log('active', active)
        if (state.dataproducto.length < 1 || active === 0) {
            const { idSede } = rootState.users.user
            //console.log('rootState.users', rootState.users)
            const { data } = await request({
                url: `/ms/migraciones/v1.0/products?typeProduct=1&idSede=${idSede}`,
                method: 'GET',
                headers: {
                    'content-type': 'application/json'
                }
            })
            commit('UPDATE_DATAPRODUCTO', data.data)
        } else {
            //console.log('get product from cache')
        }
    },
    async services({ rootState, commit, state }, payload) {
        console.log('services_log')
        const active = payload['active']
        //console.log('active', active)
        if (state.servicios.length < 1 || active === 0) {
            const { idSede } = rootState.users.user
            //console.log('rootState.users', rootState.users)
            const { data } = await request({
                url: `/ms/migraciones/v1.0/products?typeProduct=2&idSede=${idSede}`,
                method: 'GET',
                headers: {
                    'content-type': 'application/json'
                }
            })
            console.log('data_services', data)
            commit('UPDATE_LISTA_SERVICIOS', data.data)
        } else {
            //console.log('get product from cache')
        }
    },
    async getLista({ commit, rootState, state }, payload) {
        const { idSede, idBussines } = rootState.users.user
        /* idBussines :2 , share products */
        //console.log('get lista')
        if (state.listaProductos.length < 1 || payload.online) {
            //console.log('productos_from_server')
            let query = encodeURIComponent(`{"where":{"and":[{"idSede": ${idSede}}]}}`)
            if ([2, 4].includes(idBussines)) {
                query = encodeURIComponent(`{"where":{"and":[{"idBussines": ${idBussines}}]}}`)
            }

            const response = await request({
                url: `/api-loopback/tb_products?filter=${query}`,
                method: 'GET',
                headers: {
                    'content-type': 'application/json'
                }
            })
            // //console.log(response.data);
            commit('UPDATE_LISTA', response.data)
        } else {
            //console.log('productos_from_cache')
        }
    },
    async buscarProducto({ commit, rootState, state }, payload) {
        //console.log('payload', payload)
    },

    async postLista({ rootState }, payload) {
        //console.log('productos ', payload)
        // ////console.log(payload)
        const data = {
            idSede: rootState.users.user.idSede,
            idBussines: rootState.users.user.idBussines,
            ...payload
        }
        // //console.log(data);
        const response = await request({
            url: '/api-loopback/tb_products',
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            data
        })
        // //////console.log(response.data);
        return response.data
    },
    async delLista({}, payload) {
        // ////console.log(payload);
        const response = await request({
            url: `/api-loopback/tb_products/${payload.id}`,

            method: 'DELETE',
            headers: {
                'content-type': 'application/json'
            }
        })
        // //////console.log(response.data);
        return response.data
    },
    async updateProducto({}, payload) {
        // //console.log("update_producto");

        const data = { ...payload }
        // //console.log(data);
        const response = await request({
            url: `/api-loopback/tb_products/${payload.id}`,
            method: 'PUT',
            headers: {
                'content-type': 'application/json'
            },
            data
        })
        // //////console.log(response.data);
        return response.data
    },
    async getListaOut({ commit, rootState }) {
        const { idSede } = rootState.users.user
        /* {"where":{"and":[{"idSede": 4},{"stock":1},{"quantity":{"between":[0,5]}}]}} */

        const response = await request({
            //url: `/api-loopback/tb_products?filter={"where":{"and":[{"idSede": ${idSede}},{"stock":1},{"quantity":{"between":[-100,2]}}]}, "order":"quantity ASC"}`,
            url: `/api-loopback/tb_products?filter={"where":{"and":[{"idSede": ${idSede}}]}}`,
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            }
        })
        //console.log('productos-limit')
        //console.log(response.data)
        commit('UPDATE_LISTA_OUT', response.data)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
