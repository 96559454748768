import request from '@/utils/request'
import { sortItems } from '@/utils/genericUtils'
const state = {
    listaProveedor: [],
    listDispatchers: [],
    listDrivers: [],
    listTransport: [],
    listPlataformWeb: []
}
const getters = {
    listaProveedor(state, getters, rootState) {
        const departamentos = rootState.sedes.departamentos || []
        let items = []
        //console.log('(state.listaProveedor', state.listaProveedor)
        if (state.listaProveedor.length > 0) {
            state.listaProveedor.map((x) => {
                const ubigeo = x.ubigeo ? x.ubigeo.substr(0, 2) : ''
                ////console.log('x', x.ubigeo)
                const fnDepartamento = departamentos.find((y) => y.id == ubigeo)
                ////console.log('fnDepartamento ', fnDepartamento)
                items.push({
                    ...x,
                    _ubigeo: fnDepartamento ? fnDepartamento.name : null,
                    name: `${x.numeroDocumento}-${x.full_name}`
                })
            })
        }
        //console.log('iotems', items)
        return sortItems(items, 'id', 1)
    }
}
const mutations = {
    /*   UPDATE_LISTA_PRECIO(state, payload) {
    state.listaPrecio = payload;
  }, */
    UPDATE_LISTA(state, payload) {
        state.listaProveedor = payload
    },
    UPDATE_LISTA_DISPATCHERS(state, payload) {
        state.listDispatchers = payload
    },
    UPDATE_LISTA_DRIVERS(state, payload) {
        state.listDrivers = payload
    },
    UPDATE_LISTA_TRANSPORT(state, payload) {
        state.listTransport = payload
    },
    UPDATE_LISTA_WEB_PLATAFORM(state, payload) {
        state.listPlataformWeb = payload
    }
}

const actions = {
    async getLista({ commit, state, rootState }, payload) {
        // //console.log("rootState ", rootState);
        const { idBussines, idSede } = rootState.users.user
        // //console.log("query :", query);
        if (state.listaProveedor.length < 1 || payload) {
            const response = await request({
                url: `/api-loopback/tm_proveedores/getClienteSede/${idBussines}/${idSede}`,
                method: 'GET',
                headers: {
                    'content-type': 'application/json'
                }
            })
            // ////console.log("getlista");
            // ////console.log(response.data);
            //console.log('proveedor_from_server', response.data)
            commit('UPDATE_LISTA', response.data)
        } else {
            //console.log('proveedor_from_cache')
        }
    },
    async postProveedor({ rootState, state }, data) {
        const { idSede } = rootState.users.user

        /* if (rootState.users.user.id && !state.sede.id) { */
        // //console.log("POST_SEDE");
        //console.log(data)
        const response = await request({
            url: '/api-loopback/tm_proveedores',
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            data: {
                ...data
            }
        })
        return response.data
        // //console.log(response.data);
    },
    async deleteProveedor({ rootState, state }, payload) {
        // //console.log("DELETE_SEDE");
        const response = await request({
            url: `/api-loopback/tm_proveedores/${payload.id}`,

            method: 'DELETE',
            headers: {
                'content-type': 'application/json'
            }
        })
        // //console.log(response.data);
    },
    async updateProveedor({}, data) {
        //const { idSede } = rootState.users.user

        //console.log('BODY', data)
        const response = await request({
            url: `/api-loopback/tm_proveedores/updateProvPersona`,
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            data
        })
        //console.log('POST_PROVEEDOR', response.data)
    },
    async getClienteExternal({ commit, state, rootState }, payload) {
        // //console.log("GET_CLIENTE");
        const { idBussines, idSede, idColaborador } = rootState.users.user
        const query = rootState.utilities.tipoDocumento.find((x) => x.id == payload.idTipoDocumento)
        ////console.log('query ', query)

        const { data } = await request({
            url: `/api-orquestador/proveedores/v2.0/intranet`,
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            data: {
                tipoDocumento: query.text,
                idBussines,
                idSede,
                idColaborador,
                ...payload
            }
        })
        //console.log('clientes_external_from_server', data)
        return data
    },
    async findProveedor({ commit, state, rootState }) {
        const idSede = rootState.users.user.idSede
        const { data } = await request({
            url: `/ms/migraciones/v1.0/distribuidor?idSede=${idSede}`,
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            }
        })
        commit('UPDATE_LISTA_DISPATCHERS', data)
    },
    async getAllPLataformWeb({ commit, state, rootState }) {
        const idSede = rootState.users.user.idSede
        const { data } = await request({
            url: `/ms/migraciones/v1.0/plataformaWebs?idSede=${idSede}`,
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            }
        })
        commit('UPDATE_LISTA_WEB_PLATAFORM', data)
    },
    async findDriver({ commit, state, rootState }) {
        const idSede = rootState.users.user.idSede
        const { data } = await request({
            url: `/ms/migraciones/v1.0/drivers?idSede=${idSede}`,
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            }
        })
        commit('UPDATE_LISTA_DRIVERS', data)
    },
    async findTransport({ commit, state, rootState }) {
        const idSede = rootState.users.user.idSede
        const { data } = await request({
            url: `/ms/migraciones/v1.0/transports?idSede=${idSede}`,
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            }
        })
        commit('UPDATE_LISTA_TRANSPORT', data)
    },
    async createCliente({ rootState }, payload) {
        const { idSede, idBussines } = rootState.users.user

        const data = {
            ...payload,

            idBussines,
            idSede,
            fecha_nacimiento: payload.fecha_nacimiento
        }

        const response = await request({
            url: '/api-orquestador/proveedores/v2.0/intranet/create',
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            data
        })
        //console.log('POST_CLIENTE', response.data)
        return response.data
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
