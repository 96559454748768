import request from '@/utils/request'

const state = {
    listaMassage: [],
    listaPrecioMassage: []
}

const mutations = {
    UPDATE_LISTA_PRECIO(state, payload) {
        state.listaPrecioMassage = payload
    },
    UPDATE_LISTA(state, payload) {
        state.listaMassage = payload
    }
}

const getters = {
    listMassAvailable(state) {
        return state.listaPrecioMassage
    }
}

const actions = {
    async getTable({ commit, rootState, state }, payload) {
        const { idSede } = rootState.users.user
        ////console.log("payload ",payload, )
        if (state.listaMassage.length < 1 || payload) {
            //console.log('masaje_from_server')
            const response = await request({
                url: `/api-loopback/tb_mas_sedes?filter={"where":{"and":[{"id_sede_mas": ${idSede}}]}}`,

                method: 'GET',
                headers: {
                    'content-type': 'application/json'
                }
            })
            commit('UPDATE_LISTA', response.data)
        } else {
            //console.log('masaje_from_cache')
        }
    },

    async postTable({ commit, rootState }) {
        const response = await request({
            url: '/api-loopback/tb_mas_sedes',

            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            data: {
                idSede: rootState.users.user.idSede,
                mixto: payload.mixto
            }
        })
    },

    async getLista({ rootState, commit, state }, payload) {
        const { idSede } = rootState.users.user
        if (state.listaPrecioMassage.length < 1 || payload) {
            //console.log('list_masaje_from_server')
            const response = await request({
                url: `/api-loopback/tb_price_mas?filter={"where":{"and":[{"idSede": ${idSede}}]}}`,

                method: 'GET',
                headers: {
                    'content-type': 'application/json'
                }
            })

            commit('UPDATE_LISTA_PRECIO', response.data)
        } else {
            //console.log('list_masaje_from_cache')
        }
    },

    async postLista({ rootState }, payload) {
        const data = {
            idColaborador: rootState.users.user.id,
            id_mas_sede: payload.id,
            idSede: rootState.users.user.idSede,
            description: payload.description,
            hours: payload.hours,
            price: parseFloat(payload.price)
        }
        // ////console.log(data);
        const response = await request({
            url: '/api-loopback/tb_price_mas',

            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            data
        })
        // //////console.log(response.data);
        return response.data
    },
    async delLista({}, payload) {
        // ////console.log(payload);
        const response = await request({
            url: `/api-loopback/tb_price_mas/${payload.id}`,

            method: 'DELETE',
            headers: {
                'content-type': 'application/json'
            }
        })
        // //////console.log(response.data);
        return response.data
    },
    async updateMasaje({}, payload) {
        // ////console.log(payload);
        const data = { ...payload }
        const response = await request({
            url: `/api-loopback/tb_price_mas/${payload.id}`,

            method: 'PUT',
            headers: {
                'content-type': 'application/json'
            },
            data
        })
        // //////console.log(response.data);
        return response.data
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
