import request from '@/utils/request'
import axios from 'axios'
import { format, parseISO, subDays } from 'date-fns'
import { sortItems } from '@/utils/genericUtils'

const state = {
    listReparto: []
}
const getters = {
    listReparto: (state) => {
        return state.listReparto
    }
}
const mutations = {
    UPDATE_LIST_REPARTO(state, payload) {
        state.listReparto = payload
    }
}
const actions = {
    async entregadoSodimac({ commit, rootState }, payload) {
        console.log('payload', payload)
        await Promise.all([
            request({
                url: `/api-orquestador/v2.0/puppeteer/recepcionConforme`,
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                data: payload
            }),
            request({
                url: `/api-loopback/tm_ventas/${payload.id}`,
                method: 'PATCH',
                headers: {
                    'content-type': 'application/json'
                },
                data: { atributos: payload.atributos }
            })
        ])
    },
    async enCaminoSodimac({ commit, rootState }, payload) {
        console.log('payload', payload)
        await request({
            url: `/api-orquestador/v2.0/puppeteer/iniciarRecorrido`,
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            data: payload
        })
    },

    async detailsUbigeo({ commit, rootState }, payload) {
        console.log('payload', payload)
        const { data } = await request({
            url: `/api-loopback/ubigeo_peru_districts/getDataUbigeoById/${payload}`,
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            }
        })
        console.log('data', data)
        return data
    },

    async getListReparto({ commit, rootState }, payload) {
        console.log("payload---",payload)
        const { data } = await request({
            url: '/api-orquestador/reporte/v1.0/reparto',
            method: 'POST',
            headers: {
                'content-type': 'application/json'
                //
            },
            data: payload
        })
        console.log('data23---', data)
        commit('UPDATE_LIST_REPARTO', data)
    },
    async getListChofer({ commit, rootState }, { estados, fechaFiltro, date }) {
        console.log('fechaFiltro_getListChofer', fechaFiltro)
        const query = { where: { and: [{ idEstado: { inq: [8, 13] } }, { status: 1 }] } }
        console.log('query ', query)
        console.log('estados ', estados)
        const queryEncode = encodeURIComponent(JSON.stringify(query))
        console.log('queryEncode ', queryEncode)
        const { data } = await request({
            url: `/api-loopback/tm_ventas?filter=${queryEncode}`,
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            }
        })
        const list_Products = []
        console.log('getListReparto_data', data)
        data.map((x) => {
            let producto = ''
            x.productos.map((y) => (producto = producto.concat(y.description, ' ')))
            const findEstado = estados.find((y) => y.id === x.idEstado)
            const descEstado = x.idEstado ? findEstado.description : '-'
            list_Products.push({ ...x, productos: producto ? producto : '-', estado: descEstado, idOrdenEstado: findEstado.order })
            producto = ''

            // x.productos.map((y) => //console.log('desc', y.description))
        })
        const newList = sortItems(list_Products, 'idOrdenEstado', 0)
        commit('UPDATE_LIST_REPARTO', newList)
        // //console.log('UP_COTI', response.data)
    }
}
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
