import request from '@/utils/request'

const state = {
    sede: {
        province: null,
        detail: null,
        id: null
    },
    sedes: [],
    warehouses: [],
    custom_warehouses: [],
    ubigeos: [],
    departamentos: [],
    provincias: [],
    distritos: []
}
const getters = {
    _sedes: (state, getters, rootState) => {
        const { idBussines } = rootState.users.user

        if (state.sedes.length > 0 && rootState.bussines.listaBussines.length > 0) {
            let items = []
            state.sedes
                .filter((x) => x.idBussines == idBussines)
                .map((x) => {
                    /*                     //console.log('state.bussines ', state.listaBussines)
                     */ const isBussines = rootState.bussines.listaBussines.find((y) => y.id == x.idBussines)
                    items.push({ ...x, detail: `${x.province} - ${isBussines.shortName}` })
                })

            return items
        } else {
            return []
        }
    }
}

const mutations = {
    UPDATE_WAREHOUSES(state, payload) {
        state.warehouses = payload
    },
    UPDATE_CUSTOM_WAREHOUSES(state, payload) {
        state.custom_warehouses = payload
    },
    UPDATE_SEDE(state, payload) {
        state.sede = payload
    },
    UPDATE_SEDES(state, payload) {
        state.sedes = payload
    },
    UPDATE_DEPARTAMENTO(state, payload) {
        state.departamentos = payload
    },
    UPDATE_PROVINCIA(state, payload) {
        state.provincias = payload
    },
    UPDATE_DISTRITO(state, payload) {
        state.distritos = payload
    },
    UPDATE_UBIGEO(state, payload) {
        state.ubigeos = payload
    },
    RESET_UBIGEO(state, payload) {
        state.distrito = []
        state.provincia = []
    }
}
const actions = {
    async detailsAllUbigeo({ commit, rootState }, payload) {
        console.log('payload', payload)
        const { data } = await request({
            url: `/api-loopback/ubigeo_peru_districts`,
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            }
        })
        console.log('data', data)
        commit('UPDATE_UBIGEO', data)
    },
    getCustomWarehouses({ commit, rootState, state }) {
        commit('UPDATE_CUSTOM_WAREHOUSES', [
            { id: 1, description: 'TIENDA' },
            { id: 2, description: 'ALMACEN-TIENDA' },
            { id: 3, description: 'TALLER 1' },
            { id: 4, description: 'CHILLON' },
            { id: 5, description: 'Almacen Gerencia' },
            { id: 6, description: 'Almacen Chiclayo' },
            { id: 7, description: 'Almacen Lima sur' },
        ])
    },
    async getWarehouses({ commit, rootState, state }) {
        // //console.log("GET_SEDE");
        const { data } = await request({
            url: `/ms/migraciones/v1.0/${rootState.users.user.idSede}/warehouses`,
            method: 'get',
            headers: {
                'content-type': 'application/json'
            }
        })
        console.log('warehouses', data)
        commit('UPDATE_WAREHOUSES', data.data)
    },
    async getSede({ commit, rootState, state }) {
        if (rootState.users.user.id && !state.sede.id) {
            // //console.log("GET_SEDE");
            const response = await request({
                url: `/api-loopback/tb_sedes/${rootState.users.user.idSede}`,

                method: 'GET',
                headers: {
                    'content-type': 'application/json'
                }
            })
            // //console.log(response.data);
            commit('UPDATE_SEDE', response.data)
        } else {
            //console.log('own_sede_from_cache')
        }
    },
    async getAllSedes({ commit, rootState, state }) {
        // //console.log("GET_SEDE");
        if (state.sedes.length < 1) {
            const response = await request({
                url: `/api-loopback/tb_sedes`,
                method: 'GET',
                headers: {
                    'content-type': 'application/json'
                }
            })
            //console.log('all_sedes_from_server', response.data)

            commit('UPDATE_SEDES', response.data)
        } else {
            //console.log('all_sedes_from_cache')
        }
    },
    async getDepartamento({ commit, rootState, state }) {
        //console.log('getDepartamento')
        if (state.departamentos.length < 1) {
            // //console.log("GET_SEDE");
            //console.log('departamentos_from_server')
            const response = await request({
                url: `/ms/inventario/private/1.0/depa`,
                method: 'GET',
                headers: {
                    'content-type': 'application/json'
                }
            })
            // //console.log(response.data);
            commit('UPDATE_DEPARTAMENTO', response.data)
        } else {
            //console.log('own_departamento_from_cache')
        }
    },
    async getProvincia({ commit, state }, payload) {
        // //console.log("GET_SEDE");
        //console.log('provincias_from_server')
        const response = await request({
            url: `/ms/inventario/private/1.0/prov/${payload}`,
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            }
        })
        // //console.log(response.data);
        commit('UPDATE_PROVINCIA', response.data)
    },
    async getDistrito({ commit, state }, payload) {
        // //console.log("GET_SEDE");
        const response = await request({
            url: `/ms/inventario/private/1.0/dist/${payload}`,
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            }
        })
        //console.log(response.data);
        commit('UPDATE_DISTRITO', response.data)
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,

    getters
}
