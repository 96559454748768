export class Ventas {
    constructor(payload) {
        return {
            id: payload.id || '',
            check_in: payload.check_in || '',
            estado: payload.estado || '',
            sede: payload.sede || '',
            idTipoVenta: payload.idTipoVenta || '',
            tipoVenta: payload.tipoVenta || '',
            full_name: payload.full_name || '',
            numeroDocumento: payload.numeroDocumento || '',
            idCotizacion: payload.idCotizacion || '',
            moneda: payload.moneda || '',
            tipoCambio: payload.tipoCambio || '',
            tipoComprobante: payload.tipoComprobante || '',
            numeroComprobante: payload.numeroComprobante || '',
            numeroGuiaRemision: payload.numeroGuiaRemision || '',
            bancoDestino: payload.bancoDestino || '',
            nombreColaborador: payload.nombreColaborador || '',
            detalle: payload.detalle || '',
            idOrigen: payload.idOrigen || '',
            tipoPago: payload.tipoPago || '',
            importeAbonadoSoles: payload.importeAbonadoSoles || '',
            importeAbonadoDolares: payload.importeAbonadoDolares || ''
        }
    }
}
export class Gastos {
    constructor(payload) {
        return {
            fecha: payload.fechaEmision || '',
            sede: payload.sede || '',
            nombreColaborador: payload.nombreColaborador || '',
            tipoComprobante: payload.tipoComprobante || '',
            numeroComprobante: payload.numeroComprobante || '',
            razonSocial: payload.razonSocial || '',
            numeroDocumento: payload.numeroDocumento || '',
            tipoPago: payload.tipoPago || '',
            categoria: payload.categoria || '',
            detalle: payload.detalle || '',
            moneda: payload.moneda || '',
            importe: payload.importe || '',
            tipoCambio: payload.tipoCambio || '',
            importeTotalSoles: payload.importeTotalSoles || ''
        }
    }
}
export class Orden {
    constructor(payload) {
        return {
            id: payload.id || '',
            check_in: payload.check_in || '',
            bussines: {
                ruc: payload.bussines.ruc || '',
                direccion: payload.bussines.direccion || '',
                correo: payload.bussines.correo || '',
                telefono: payload.bussines.telefono || '',
                check_in: payload.bussines.check_in || ''
            },
            importes: {
                sT: payload.importes.sT,
                dT: payload.importes.dT,
                iT: payload.importes.iT,
                tL: payload.importes.tL
            },
            full_name: payload.full_name || '',
            numeroDocumento: payload.numeroDocumento || '',
            telefono: payload.telefono || '',
            correo: payload.correo || '',
            observation: payload.observation || '',
            tipoComprobante: payload.tipoComprobante || '',
            numeroComprobante: payload.numeroComprobante || '',
            numeroGuiaRemision: payload.numeroGuiaRemision || '',
            bancoDestino: payload.bancoDestino || '',
            monedaCliente: payload.monedaCliente || '',
            tipoCambio: payload.tipoCambio || '',
            efectivo: payload.efectivo || '',
            tarjeta: payload.tarjeta || '',
            transferencia: payload.transferencia || '',
            destino: payload.destino || '',
            formaEntrega: payload.formaEntrega || '',
            agenciaEnvio: payload.agenciaEnvio || '',
            celularContacto: payload.celularContacto || '',
            direccion: payload.direccion || '',
            destinoFinal: payload.destinoFinal || '',
            direccionFacturacion: payload.direccionFacturacion || '',
            referencia: payload.referencia || '',
            googleMaps: payload.googleMaps || '',
            tipoEntrega: payload.tipoEntrega || '',
            montoDelivery: payload.montoDelivery || '',
            formaPago: payload.formaPago || '',
            idCotizacion: payload.idCotizacion || '',
            sede: payload.sede || '',
            moneda: payload.moneda || '',
            nombreColaborador: payload.nombreColaborador || '',
            nombreContacto: payload.nombreContacto || '',
            itemsProductos: payload.itemsProductos || '',
            importeAbonado: payload.importeAbonado || '',
            importeAbonadoSoles: payload.importeAbonadoSoles || '',
            importeAbonadoDolares: payload.importeAbonadoDolares || '',
            importeTotalSoles: payload.importeTotalSoles || '',
            departamento: payload.departamento || '',
            provincia: payload.provincia || '',
            distrito: payload.distrito || '',
            documentoContacto:payload.documentoContacto || ''
        }
    }
}
