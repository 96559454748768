import { timeout, setToken, removeToken } from '@/utils/auth'
import request from '@/utils/request'

const state = {
    user: {
        idBussines: 0,
        nombres: null,
        apellidos: null,
        correo: null,
        password: null,
        role: 0,
        caja_open: false,
        id: null,
        idSede: null,
        optionalRole: false
    },
    listModules: [],
    listaUser: [],
    tkExternal: null,
    token: '',
    listaColaboradores: [],
    roles: [],
    personalCargo: [],
    personal: [],
    allColaborador: []
}

const getters = {
    listHabAvailable(state) {
        return state.listaPrecio
    },
    optionalRole(state) {
        return JSON.parse(state.user['optionalRole'])
    },
    listOwnModules(state) {
        const unique = [...new Set(state.user.listOwnModules.map((item) => item.idModule))] // [ 'A', 'B']
        //console.log("unique ",unique)
        let newList = []
        unique.map((x) => {
            const items = state.user.listOwnModules.filter((y) => x == y.idModule)
            newList.push({ modules: x, items, icon: items[0].icon })
        })
        return newList
    },
    newListOwnModules(state) {
        const rootRoutes = []
        const paths = state.user.listOwnModules
        for (const item of paths) {
            const segments = item.toPath.split('/').filter((segment) => segment.trim() !== '')
            let currentRoutes = rootRoutes
            let currentPath = ''
            for (const segment of segments) {
                currentPath += '/' + segment
                let route = currentRoutes.find((r) => r.path === currentPath)
                if (!route) {
                    route = {
                        path: currentPath,
                        name: segment,
                        originalPath: item.toPath, // Añadir la ruta original al objeto de ruta
                        children: []
                    }
                    currentRoutes.push(route)
                }
                currentRoutes = route.children
            }
        }
        return rootRoutes
    },
    listaChofer(state) {
        let lista = []
        const idBussines = state.user['idBussines']
        console.log('idBussines-----> ', idBussines)
        console.log(
            'idBussines-----> ',
            state.listaUser.filter((x) => x.idBussines == idBussines)
        )
        state.listaUser
            .filter((x) => x.idBussines == idBussines)
            .map((x) => {
                console.log('x ', x)
                const oRole = x['optionalRole']
                const extra = oRole ? (oRole['extra'] ? oRole['extra'] : []) : []
                const listaExtra = extra.filter((y) => y.idExtra == 1)
                if (listaExtra.length > 0) {
                    lista.push({ ...x, fullName: `${x.nombres} ${x.apellidos}` })
                }
            })
        return lista
    }
}

const mutations = {
    SET_TOKEN: (state, p) => {
        state.token = p
    },
    SET_ROLES: (state, p) => {
        state.roles = p
    },
    UPDATE_LISTA(state, payload) {
        state.listaUser = payload
    },
    SET_VENDEDORES(state, payload) {
        state.listaVendedores = payload
    },
    SET_PERSONALCARGO(state, payload) {
        state.personalCargo = payload
    },
    SET_LIST_MODULES(state, payload) {
        state.listModules = payload
    },
    SET_PERSONAL(state, payload) {
        state.personal = payload
    },
    UPDATE_USER(state, payload) {
        state.user = payload
    },
    UPDATE_EXT_TK(state, payload) {
        state.tkExternal = payload
    },
    UPDATE_COLABORADOR(state, payload) {
        state.allColaborador = payload
    }
}

const actions = {
    async getPersonas({ state, commit, rootState }, payload) {
        const colaboradores = []
        const { idSede, idBussines } = rootState.users.user
        if (state.personalCargo.length < 1) {
            const filter = { where: { and: [{ idBussines: idBussines }, { status: 1 }, { idSede: idSede }] } }
            const encodedFilter = encodeURIComponent(JSON.stringify(filter))

            const { data } = await request({
                url: `/api-loopback/tb_colaboradors?filter=${encodedFilter}`,
                method: 'GET',
                headers: {
                    'content-type': 'application/json'
                }
            })
            //console.log('data_users', data)
            data.map((x) => {
                if (x.status) colaboradores.push(x.nombres)
            })
            commit('SET_PERSONAL', colaboradores)
        }
        //console.log('colaboradores', colaboradores)
    },
    async getItem({ commit, state }, payload) {
        const filter = { where: { and: [{ status: 1 }] } }
        const encodedFilter = encodeURIComponent(JSON.stringify(filter))

        const response = await request({
            url: `/api-loopback/tb_colaboradors/${payload}?filter=${encodedFilter}`,
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            }
        })
        //console.log('getlista', response.data)
        // ////console.log(response.data);
        return response.data
        //commit('UPDATE_LISTA', response.data)
    },
    async getLista({ commit, state }) {
        const query = encodeURIComponent(`{"where":{"and":[{"idBussines":${state.user.idBussines}},{"status": 1}]}}`)
        // if (state.listaUser.length < 1) {
        const listUser = []
        const response = await request({
            url: `/api-loopback/tb_colaboradors?filter=${query}`,
            // url: `/api-loopback/tb_colaboradors`,
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            }
        })
        response.data.map((x) => {
            listUser.push({ ...x })
        })
        commit('UPDATE_LISTA', listUser)
        // }
    },
    async allColaborador({ commit, state }) {
        const { data } = await request({
            url: `/api-loopback/tb_colaboradors?filter={"where":{"and":[{"status": 1}]}}`,
            // url: `/api-loopback/tb_colaboradors`,
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            }
        })
        commit('UPDATE_COLABORADOR', data)
    },
    async postUser({ rootState }, payload) {
        const body = {
            ...payload,
            tipo: 1, //POST
            idSede: rootState.users.user.idSede,
            idBussines: rootState.users.user.idBussines,
            usuario: payload.usuario,
            selectModule: payload.optionalRole.modules
        }
        console.log('dataform', body)
        const { data } = await axios({
            url: `/api-orquestador/users/v2.0/login/add`,
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            data: body
        })
        return data
    },
    async delUser({}, payload) {
        const response = await request({
            url: `/api-loopback/tb_colaboradors/${payload.id}`,
            method: 'PATCH',
            headers: {
                'content-type': 'application/json'
            },
            data: {
                ...payload,
                status: 0
            }
        })
        // //////console.log(response.data);
        return response.data
    },
    async updateUser({}, payload) {
        await request({
            url: `/api-orquestador/users/v2.0/login/add`,
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            data: {
                ...payload,
                tipo: 2 //PUT
            }
        })
        // ////console.log("getlista");
        // ////console.log(response.data);
    },
    async listModules({ commit }) {
        //console.log()
        const { data } = await request({
            url: `/api-loopback/tm_paths`,
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            }
        })
        commit('SET_LIST_MODULES', data)
    },
    async validateLogin({ commit }, { data, self }) {
        try {
            //console.log('paylaod--->', data)
            const response = await request({
                url: `/api-orquestador/users/v2.0/login`,
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                data
            })

            // GET SEDE
            //console.log('resultado: ', response.data)
            if (response.data.codRes === 200) {
                const jwtToken = response.data.token
                setToken(jwtToken)
                commit('UPDATE_EXT_TK', response.data.tkExternal)
                return response.data
            } else {
                return {
                    user: null,
                    bussines: null
                }
            }
        } catch (error) {
            //console.log('ERROR: ', error)
            return {
                user: null,
                bussines: null
            }
        }
    },
    resetToken({ commit }) {
        return new Promise(async (resolve) => {
            //console.log('RESET_ALL')
            commit('SET_TOKEN', '')
            commit('SET_ROLES', [])
            localStorage.clear()
            removeToken()
            await timeout(1500)
            location.reload()
            resolve()
        })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
