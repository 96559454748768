import Vuex from 'vuex'
import Vue from 'vue'
import { VersionAPP } from '../utils/auth'
import createPersistedState from 'vuex-persistedstate'
import privados from './modules/gestion/privados'
import asistencia from './modules/gestion/asistencia'
import masajes from './modules/gestion/masajes'
import mixtos from './modules/gestion/mixtos'
import productos from './modules/gestion/productos'
import redis from './modules/gestion/redis'
import inventario from './modules/gestion/inventario'
import users from './modules/users'
import ventas from './modules/ventas'
import printer from './modules/printer'
import sedes from './modules/sedes'
import category from './modules/category'
import caja from './modules/caja'
import gastos from './modules/gastos'
import reporte from './modules/reporte'
import clientes from './modules/clientes'
import bussines from './modules/bussines'
import mainUI from './modules/mainUI'
import proveedor from './modules/proveedor'
import cotizaciones from './modules/cotizaciones'
import utilities from './modules/utilities'
import pagos from './modules/pagos'
import compras from './modules/compras'
import reparto from './modules/reparto'

Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [
        createPersistedState({
            key: VersionAPP,
            // paths: ['bussines','sedes', 'productos', 'clientes', 'proveedor', 'utilities', 'users']
            paths: ['bussines', 'sedes', 'proveedor', 'utilities', 'users']
            //paths: ['bussines', 'category', 'sedes', 'proveedor', 'productos', 'clientes', 'users',]
        })
    ],
    modules: {
        pagos,
        utilities,
        bussines,
        redis,
        proveedor,
        asistencia,
        privados,
        masajes,
        mixtos,
        users,
        productos,
        inventario,
        ventas,
        printer,
        mainUI,
        caja,
        sedes,
        clientes,
        gastos,
        reporte,
        category,
        cotizaciones,
        compras,
        reparto
    }
})
