<template>
    <v-app id="inspire">
        <v-app-bar app hide-on-scroll class="white" flat>
            <v-app-bar-nav-icon @click="openDrawer"></v-app-bar-nav-icon>
            <v-toolbar-title class="headline text-uppercase" to="/">
                <span>{{ bussines.shortName }}</span>
                <i class="font-weight-light"> & SPA</i>
            </v-toolbar-title>
            <v-spacer />
            <v-toolbar-items> </v-toolbar-items>
            <v-spacer />
            <v-btn rounded small color="purple" :loading="loadingSync" dark @click="sincronizarProductos({ online: true })">
                <v-icon right dark> mdi-autorenew </v-icon>&nbsp; sincronizar
            </v-btn>

            <v-chip class="ml-2" outlined color="green"> <v-icon left> mdi-spa </v-icon> {{ versionNameDist }} </v-chip>
        </v-app-bar>

        <v-navigation-drawer v-model="drawer" app width="240" dark :mini-variant.sync="mini">
            <v-list>
                <v-list-item class="px-2">
                    <v-list-item-avatar>
                        <v-img src="../assets/logo.jpg"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-title dark> {{ user.nombres }} {{ user.apellidos }}</v-list-item-title>
                    <v-btn icon @click.stop="mini = !mini">
                        <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                </v-list-item>
                <v-divider></v-divider>
                <v-list nav dense>
                    <v-list-group :prepend-icon="item.icon" :value="key == 0" v-for="(item, key) in listOwnModules" :key="key">
                        <template v-slot:activator>
                            <v-list-item-content>
                                <v-list-item-title>{{ item.modules }}</v-list-item-title>
                            </v-list-item-content>
                        </template>
                        <v-list-item v-for="(itemChild, keyChild) in item.items" exact-path :to="itemChild.toPath" :key="keyChild" @click="routePath(keyChild)">
                            <v-list-item-content>
                                <v-list-item-title>{{ itemChild.title.toUpperCase() }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-group>
                </v-list>
            </v-list>

            <template v-slot:append>
                <div class="pa-2">
                    <v-btn block @click="cerrarSesion" color="red" v-if="!mini"> Cerrar Sesión </v-btn>
                </div>
            </template>
        </v-navigation-drawer>

        <v-main class="grey lighten-3">
            <v-container fluid>
                <router-view></router-view>
            </v-container>
        </v-main>

        <v-footer height="48">
            <v-spacer></v-spacer>
            <strong class="caption">
                Powered
                <v-icon color="yellow">mdi-lightbulb-on-outline </v-icon><a href="https://innout.pe">innout.pe</a>
            </strong>
            <v-spacer></v-spacer>
        </v-footer>
    </v-app>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { VersionAPP } from '@/utils/auth'

export default {
    data: () => ({
        drawer: true,
        mini: true,
        loadingSync: false,
        clipped: true,
        fixed: false,
        miniVariant: false,
        apps: [
            ['Management', 'mdi-account-multiple-outline'],
            ['Settings', 'mdi-cog-outline']
        ],
        cruds: [
            ['Create', 'mdi-plus-outline'],
            ['Read', 'mdi-file-outline'],
            ['Update', 'mdi-update'],
            ['Delete', 'mdi-delete']
        ],
        right: true,
        rightDrawer: false
    }),
    computed: {
        ...mapGetters('users', ['optionalRole', 'listOwnModules']),
        ...mapState('users', ['userLogin', 'roles', 'user']),
        ...mapState('bussines', ['bussines']),
        versionNameDist() {
            return VersionAPP
        },
        ...mapState('sedes', ['version'])
    },
    methods: {
        async sincronizarProductos(p) {
            this.loadingSync = true
            //  await this.$store.dispatch('productos/getLista', { online: p ? p.online : false })
            await this.$store.dispatch('redis/syncRedis', {})
            await Promise.all([
                this.$store.dispatch('productos/products', { active: 0 }),
                this.$store.dispatch('clientes/getListaCli', { active: 0 }),
                this.$store.dispatch('privados/servicios', { active: 0 })
            ])

            this.loadingSync = false
        },
        openDrawer() {
            this.drawer = !this.drawer
            this.mini = false
        },
        isExclude(item) {
            const x = this.optionalRole.excludeToAssign.filter((y) => y == item)
            return x.length > 0 ? true : false
        },
        ...mapActions({
            SIGN_OUT: 'users/resetToken'
        }),
        async cerrarSesion() {
            this.$store.commit('mainUI/OPEN_MODAL', { state: true, overlay: false, color: 'red', width: '400px', text: 'CERRANDO SESIÓN' })
            await this.SIGN_OUT()
        },
        goRoute(v) {
            this.$router.replace(v)
        },
        closeUser() {
            this.$cookies.remove(this.versionNameDist)
            this.$store.commit('users/UPDATE_USER', { id: null, idSede: 1 })
            localStorage.clear()
            this.$router.push('/')
            //location.reload()
        },
       async routePath(index){
          await this.$store.dispatch('reporte/pathRoute',index)
        }
    },
    async created() {
        await this.$store.dispatch('category/getCategory')
        //console.log('this.$route.query.openModalVenta ', this.$route.query.openModalVenta)
        const openModalVenta = this.$route.query.openModalVenta
        await this.$store.dispatch('utilities/getBancos')

        /*         if (openModalVenta) {
            this.$store.commit('ventas/SHOW_MODAL', { step: 1, state: true })
        } */
    }
}
</script>
<style>
@media only screen and (max-width: 600px) {
    .container {
        padding: 8px !important;
    }
}
</style>
