import { format, addDays } from 'date-fns'
import store from '../store'

export default class DefaultForm {
    static formDefault() {
        ////console.log('store: ', store)
        return {
            idBussines: store ? store.state.users.user.idBussines : 1,
            idSede: store ? store.state.users.user.idSede : 1,
            idColaborador: store ? store.state.users.user.id : null,
            tipoCliente: 1,
            idCliente: null,
            idTipoDocumento: 1,
            idTipoMoneda: 1,
            isEmailPreparando: 0,
            idTipoMonedaRecibido: 1,
            idTipoComprobante: 2,
            atributos: {},
            numeroComprobante: '',
            tipoCambio: 1,
            numeroDocumento: '',
            full_name: '',
            check_in: format(new Date(), 'yyyy-MM-dd'),
            observation: null,
            importeTotal: null,
            tipoPago: 1,
            barCodeGenerate: null,
            barcode: null,
            idTipoMonedaFacturacion: 1,
            correo: '',
            mixtos: [],
            privados: [],
            masajes: [],
            efectivo: 0,
            tarjeta: 0,
            transferencia: 0,
            tipoVenta: 1,
            atributos: {
                DocumentoRegistro: {
                    idTipoRegistro: 1,
                    numeroComprobante: null,
                    fechaEmision: null,
                    idTipoMonedaFacturacion: null,
                    montoFacturacion: null
                }
            },
            finalizado: null,
            montoFacturacion: null,
            direccionFacturacion: null,
            fechaFacturacion: null,
            estadoDeuda: 0,
            atributos: {
                DocumentoRegistro: {
                    idTipoRegistro: 1,
                    numeroComprobante: null,
                    fechaEmision: null,
                    idTipoMonedaFacturacion: null,
                    montoFacturacion: null
                }
            }
        }
    }

    static formCompras() {
        return {
            idColaborador: store ? store.state.users.user.id : null,
            tipoPago: 1,
            largo: null,
            alto: null,
            ancho: null,
            id_tipo_gasto: null,
            description: null,
            fechaEmision: format(new Date(), 'yyyy-MM-dd'),
            idTipoComprobante: 1,
            itemGastos: [],
            numeroComprobante: null,
            idProveedor: null,
            status: 0,
            ruc: null,
            importe: null,
            igv: null,
            isc: null,
            observaciones: null
        }
    }
    static formInventary() {
        return {
            numArea:null,
            project:null,
            nameAuthorized:null,
            nameReception:null,
            types: null,
            warehouse_id: null,
            origin_warehouse_id: null,
            oT: null,
            operation_date: format(new Date(), 'yyyy-MM-dd HH:mm'),
            numGuide: null,
            numTicket: null,
            observations: null,
            categories: null,
            products: null
        }
    }

    static formItemCompra() {
        return {
            description: null,
            importe: null,
            unidades: 1,
            idTipoMoneda: 1
        }
    }
    static formVentasRealizadas() {
        return {
            idColaborador: 1,
            fecha: format(new Date(), 'yyyy-MM-dd')
        }
    }
    static formItemPago() {
        return {
            idTipoPago: null,
            idTipoMoneda: 1,
            tipoCambio: null,
            importe: null,
            nroOperacion: null,
            fechaPago: format(new Date(), 'yyyy-MM-dd')
        }
    }
    static formService() {
        return {
            details: null,
            value: null,
            duration: null,
            tipoServicio: null,
            id: null
        }
    }
    static formSelectServicio() {
        return {
            idColaborador: store ? store.state.users.user.id : null,
            idTipoMoneda: 1,
            price: 0,
            comision: 0,
            descuentos: 0,
            unidades: 1,
            id: null,
            quantity: null,
            time: format(new Date(), 'HH:mm')
        }
    }
    static formSelectOt() {
        return {
            personalCargo: null,
            proyecto: null,
            fechaTermino: null
        }
    }

    static formProveedor() {
        return {
            idColaborador: store ? store.state.users.user.id : null,

            idBussines: store ? store.state.users.user.idBussines : 1,
            idSede: store ? store.state.users.user.idSede : 1,
            numeroDocumento: null,
            descripcion: null,
            idTipoDocumento: 6,
            rubro: '',
            direccion: '',
            celular: '',
            correo: ''
        }
    }

    static formCotizacion() {
        return {
            idTipoDocumento: null,
            idBussines: store ? store.state.users.user.idBussines : 1,
            idSede: store ? store.state.users.user.idSede : 1,
            idColaborador: store ? store.state.users.user.id : 1,
            idUsuarioCreacion: store ? store.state.users.user.id : 1,
            idUsuarioModificacion: store ? store.state.users.user.id : 1,
            fechaEmision: format(new Date(), 'yyyy-MM-dd'),
            fechaVencimiento: format(addDays(new Date(), 15), 'yyyy-MM-dd'),
            idTipoPago: 2,
            tieneDatosCliente: true,
            description: null,
            idTipoComprobante: 2,
            numeroComprobante: null,
            largo: null,
            alto: null,
            ancho: null,
            idTipoMoneda: 1,
            tipoCambio: null,
            idCliente: null,
            departamento: null,
            distrito: null,
            provincia: null,
            direccion: '',
            ubigeo: '', //DEFAULT LIMA
            itemsProductos: [],
            importe: null,
            igv: 0,
            isc: 0,
            observaciones: '',
            formaPago: '50% para la fabricación y 50% para programar el despacho.',
            sendMail: true
        }
    }

    static formClient() {
        return {
            idBussines: store ? store.state.users.user.idBussines : 1,
            idSede: store ? store.state.users.user.idSede : 1,
            idColaborador: store ? store.state.users.user.id : 1,
            idTipoDocumento: null,
            numeroDocumento: null,
            full_name: null,
            fecha_nacimiento: null,
            telefono: null,
            idCliente: null
        }
    }

    static formReport() {
        return {
            idSede: 5,
            idReport: 3,
            detalle: null,
            monto: null,
            fecha: format(new Date(), 'yyyy-MM')
        }
    }
    static formAttributes() {
        return {
            fullNameReceptor: null,
            nombreProducto: null,
            bultos: null,
            fechaEnvio: null,
            supervisor: null,
            idChofer: null,
            chofer: null,
            placaVehiculo: null,
            linkFotoProducto: null,
            guiaAgencia: null,
            fechaFabricacion: null,
            encargadoFabricacion: null,
            fechaEquipamiento: null,
            encargadoEquipamiento: null,
            fechaEmbalaje: null,
            encargadoEmbalaje: null
        }
    }
}
