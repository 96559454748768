import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import es from 'vuetify/es5/locale/es'

Vue.use(Vuetify)

export default new Vuetify({
    icons: {
        iconfont: 'mdi' // default - only for display purposes
    },
    theme: {
        options: {
            customProperties: true
        },
        themes: {
            light: {
                primary: '#d81b60',
                darkenPrimary: '#a00037',
                lightenPrimary: '#e3f2fd',
                secondary: '#d81b60',
                main: '#212121',
                secondaryMain: '#424242',
                room: '#03A9F4',
                secondaryTheme: '#448AFF'
            }
        }
    },
    lang: {
        locales: { es },
        current: 'es'
    }
})
