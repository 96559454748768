import axios from 'axios'
import store from '@/store'
import { getToken } from '@/utils/auth'
// create an axios instance
const service = axios.create({
    // withCredentials: true, // send cookies when cross-domain requests
    //timeout: 45000, // request timeout
    baseURL: process.env.VUE_APP_HOST
})

// request interceptor
service.interceptors.request.use(
    (config) => {
        if (getToken()) {
            // let each request carry token
            // ['X-Token'] is a custom headers key
            // please modify it according to the actual situation
            config.headers['Content-Type'] = 'application/json'
            config.headers.Authorization = `Bearer ${getToken()}`
        }
        return config
    },

    (error) => {
        // do something with request error
        //console.log('----sadsa', error) // for debug
        return Promise.reject(error)
    }
)
service.interceptors.response.use(
    (response) => {
        return response
    },
    async (error) => {
        let text = 'SU SESIÓN HA EXPIRADO'
        try {
            //console.log('error.response.status' + error.response.status) // for debug
            if (error.response.status == 401) {
                await store.dispatch('users/resetToken')
            } else {
                text = 'Ha ocurrido un error interno'
            }
        } catch (error) {
            text = 'Ha ocurrido un error interno'
        }
        store.commit('mainUI/OPEN_MODAL', { state: true, overlay: false, color: 'red', width: '400px', text, close: true })
        return Promise.reject(error)
    }
)

export default service
