import { format, subDays, subMonths } from 'date-fns'
import DefaultForm from '@/utils/defaultForm'
import { sortItems } from '@/utils/genericUtils'
import request from '@/utils/request'

const { formatInTimeZone } = require('date-fns-tz')
/* ## callback venta ## */
const state = {
    listaPagos: [],
    listClientPagos: [],
    external_tipoCambio: null,
    bancoTipoCambio: null
}

const getters = {}

const mutations = {
    UPDATE_LISTA_PAGOS(state, payload) {
        state.listaPagos = payload
    },
    UPDATE_EXTERNAL_TIPO_CAMBIO(state, payload) {
        state.external_tipoCambio = payload
    },
    UPDATE_EXTERNAL_BANCO_TIPO_CAMBIO(state, payload) {
        state.bancoTipoCambio = payload
    },
    UPDATE_PAGOS_CLIENT(state, payload) {
        state.listClientPagos = payload
    }
}

const actions = {
    async pagosClientes({ commit, rootState }, payload) {
        const { idSede, idBussines } = rootState.users.user
        //console.log('rootState.users.user----', rootState.users.user)

        let mainUrl
        //
        // "include": { relation: 'tm_ventas' },
        if (payload.fechaFiltro[1]) {
            const filter = { where: { and: [{ fechaPago: { between: [payload.fechaFiltro[0], payload.fechaFiltro[1]] } }, { status: 1 }] } }

            const encodedFilter = encodeURIComponent(JSON.stringify(filter))

            mainUrl = `/api-loopback/tm_pagos?filter=${encodedFilter}`
        } else {
            const filter = { where: { and: [{ fechaPago: payload.fechaFiltro[0] }, { status: 1 }] } }

            const encodedFilter = encodeURIComponent(JSON.stringify(filter))

            mainUrl = `/api-loopback/tm_pagos?filter=${encodedFilter}`
        }
        const response = await axios({
            url: mainUrl,
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            }
        })
        console.log('response.data', response.data)

        const unique = [...new Set(response.data.map((item) => item.idVenta))] // [ 'A', 'B']
        console.log('unique', unique)
        const filter = { where: { and: [{ id: { inq: [unique] } }] } }
        const encodedFilter = encodeURIComponent(JSON.stringify(filter))

        const { data } = await axios({
            url: `/api-loopback/tm_ventas?filter=${encodedFilter}`,
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            }
        })
        let items = []
        console.log('tm_ventas', data)

        response.data.map((x) => {
            const findItem = data.find((y) => y.id == x.idVenta)
            console.log('findItem,', findItem)

            const isSede = rootState.sedes.sedes.find((y) => y.id == findItem.idSede)
            console.log('isSede,', x.idVenta, idBussines, isSede)
            if (isSede.idBussines == idBussines) {
                items.push({
                    ...x,
                    idBussines,
                    idSede: findItem.idSede,
                    nameSede: isSede.sede_name_id,
                    tipoComprobante: findItem.idTipoComprobante == 1 ? findItem.numeroComprobante : findItem.numeroComprobante
                })
            }
        })
        commit('UPDATE_PAGOS_CLIENT', items)
        console.log('tm_ventasitems', items)

        return items
    },
    async enviar_moduloPago({ commit, rootState }, payload) {
        const { atributos, id, montoDelivery, idColaborador, formaPago, idBussines } = payload
        const idFormaPago = [2, 4].includes(idBussines) ? 18 : 4
        const data = {
            idTipoMoneda: 1,
            idTipoPago: atributos.metodoPago,
            idBancoDestino: null,
            idVenta: id,
            idTipoMonedaVenta: 1,
            status: 1,
            tipoCambio: null,
            importe: montoDelivery,
            idColaborador,
            fechaPago: atributos.fechaPago,
            nroOperacion: null,
            idBancoDestinoExternal: formaPago || idFormaPago
        }
        //console.log("data---",data)
        await request({
            url: `/api-loopback/tm_pagos`,
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            data
        })
    },
    async listaPagosById({ commit, rootState }, payload) {
        const idVenta = rootState.ventas.datos.id ?? payload.id
        let data = []
        //console.log('idVenta ', idVenta)
        if (idVenta) {
            const filter = { where: { and: [{ idVenta: idVenta }, { status: 1 }] } }
            const encodedFilter = encodeURIComponent(JSON.stringify(filter))

            const response = await request({
                url: `/api-loopback/tm_pagos?filter=${encodedFilter}`,
                method: 'GET',
                headers: {
                    'content-type': 'application/json'
                }
            })
            data = response.data
            //console.log('get_Lista_pagos_from_server', data)
        } else {
            //console.log('not_id_found', data)
        }
        commit('UPDATE_LISTA_PAGOS', data)
        return data
    },
    async postPagos({ commit, rootState, dispatch }, payload) {
        // const myTimezone = formatInTimeZone(new Date(), 'America/Lima', 'yyyy-MM-dd')
        const idVenta = rootState.ventas.datos.id
        //console.log('idVenta ', idVenta)
        console.log('payload', payload)

        const idColaborador = rootState.ventas.datos.idColaborador
        console.log({
            ...payload.body,
            fechaPago: formatInTimeZone(payload.body.fechaPago, 'America/Lima', 'yyyy-MM-dd'),
            status: 1,
            idVenta,
            idColaborador
        })
        console.log('idVenta', idVenta)
        if (idVenta) {
            const pago = await axios({
                url: `/api-loopback/tm_pagos`,
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                data: {
                    ...payload.body,
                    fechaPago: formatInTimeZone(payload.body.fechaPago, 'America/Lima', 'yyyy-MM-dd'),
                    status: 1,
                    idVenta,
                    idColaborador,
                    idBancoDestino: null,
                    idBancoDestinoExternal: payload.body.idBancoDestinoExternal
                }
            })
            console.log('sendPago', pago)
            await dispatch('listaPagosById', { id: payload.body.idVenta })
            if (payload.body.nroOperacion) {
                await request({
                    url: `/ms/migraciones/v1.0/pagos?idSede=${rootState.users.user.idSede}&typeAction=${payload.typeAction}`,
                    //url: `http://localhost:3002/ms/migraciones/v1.0/pagos?idSede=${rootState.users.user.idSede}&typeAction=${payload.typeAction}`,
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: {
                        ...payload.datos,
                        ...payload.body,
                        userNumeroDocumento: rootState.users.user.documento,
                        fechaPago: formatInTimeZone(payload.body.fechaPago, 'America/Lima', 'yyyy-MM-dd'),
                        status: 1,
                        idVenta,
                        idColaborador,
                        idPaymentIntranet: pago.data.id
                    }
                })
            }
        }
    },
    async deletePagos({ commit, rootState, dispatch }, payload) {
        let data = []
        console.log('payload', payload)
        if (payload.item.id) {
            const response = await request({
                url: `/api-loopback/tm_pagos/${payload.item.id}`,
                method: 'PUT',
                headers: {
                    'content-type': 'application/json'
                },
                data: {
                    ...payload.item,
                    status: 0
                }
            })
            await dispatch('listaPagosById', { id: payload.item.idVenta })
            // await axios({
            //     url: `/ms/migraciones/v1.0/pagos?idSede=${rootState.users.user.idSede}&typeAction=${payload.typeAction}`,
            //     method: 'POST',
            //     headers: {
            //         'content-type': 'application/json'
            //     },
            //     data: {
            //         ...payload.datos,
            //         ...payload.body,
            //         ...payload.item,
            //         fechaPago: formatInTimeZone(payload.item.fechaPago, 'America/Lima', 'yyyy-MM-dd'),
            //         status: 1,
            //         idVenta: payload.item.idVenta,
            //         idColaborador: payload.item.idColaborador
            //     }
            // })
        } else {
            //console.log('not_id_found', data)
        }
        // //console.log(response.data);
    },
    async tipoCambio({ commit, state, rootState, dispatch }, payload) {
        try {
            if (!state.external_tipoCambio) {
                const { data } = await axios({
                    url: `/ms/servicios/pk/v1.1/tipoCambio`,
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json',
                        Authorization: `Bearer 33825c48-243c-4c19-9406-1c4a4aabb7ac`
                    }
                })
                //console.log('UPDATE_EXTERNAL_TIPO_CAMBIO', data.data.venta)
                commit('UPDATE_EXTERNAL_TIPO_CAMBIO', data.data.venta)
            }
        } catch (error) {
            console.log('Ocurrio un error con el Api')
            commit('UPDATE_EXTERNAL_TIPO_CAMBIO', null)
        }
    },
    async bancoTipoCambio({ commit, state, rootState, dispatch }, payload) {
        try {
            console.log('tipocambio', state.bancoTipoCambio)
            if (!state.bancoTipoCambio) {
                const { data } = await axios({
                    url: `/ms/datosperu/v1.1/tipoCambio`,
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                })
                console.log('data', data)
                commit('UPDATE_EXTERNAL_BANCO_TIPO_CAMBIO', data.venta)
            }
        } catch (error) {
            commit('UPDATE_EXTERNAL_BANCO_TIPO_CAMBIO', null)
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
