import request from '@/utils/request'

const state = {}

const getters = {}

const mutations = {}

const actions = {
    async syncRedis({ rootState, commit, state }, payload) {

        const { idSede, idBussines } = rootState.users.user
        const cromJob = await request({
            url: `/ms/migraciones/v1.0/sync`,
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            data: {
                idSede, idBussines
            }
        })
        //console.log('cromJob', cromJob.data)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
