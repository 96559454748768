const _VersionAPP = 'V10.71'
export function getToken() {
    return window.$cookies.get(_VersionAPP)
}

export function setToken(token) {
    return window.$cookies.set(_VersionAPP, token, 60 * 60 * 12)
}

export function removeToken() {
    localStorage.clear()
    return window.$cookies.keys().forEach((cookie) => window.$cookies.remove(cookie))
}
export const VersionAPP = _VersionAPP
export function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms))
}
