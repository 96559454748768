import request from '@/utils/request'

const state = {
    listaPrecioMixto: [],
    listaMixto: []
}

const getters = {
    listMixAvailable(state) {
        return state.listaPrecioMixto
    }
}

const mutations = {
    UPDATE_LISTA_PRECIO(state, payload) {
        state.listaPrecioMixto = payload
    },
    UPDATE_LISTA(state, payload) {
        state.listaMixto = payload
    }
}

const actions = {
    async getTable({ commit, rootState, state }, payload) {
        const idSede = parseInt(rootState.users.user.idSede)
        // ////console.log("sede: " + idSede);
        if (state.listaMixto.length < 1 || payload) {
            //console.log('mixtos_from_server')

            const response = await request({
                url: `/api-loopback/tb_mix_sedes?filter={"where":{"and":[{"idSede": ${idSede}}]}}`,

                method: 'GET',
                headers: {
                    'content-type': 'application/json'
                }
            })
            // ////console.log("mixt-table");
            // ////console.log(response.data);
            commit('UPDATE_LISTA', response.data)
        } else {
            //console.log('mixtos_from_cache')
        }
    },

    async postTable({ commit, rootState }) {
        const response = await request({
            url: '/api-loopback/tb_mix_sedes',

            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            data: {
                idSede: rootState.users.user.idSede,
                mixto: payload.mixto
            }
        })
        // //console.log(response.data);
        // commit("UPDATE_LISTA", response.data);
    },
    async getLista({ commit, rootState, state }, payload) {
        if (state.listaPrecioMixto.length < 1 || payload) {
            //console.log('list_mixtos_from_server')

            const { idSede } = rootState.users.user
            const response = await request({
                url: `/api-loopback/tb_price_mixes?filter={"where":{"and":[{"idSede": ${idSede}}]}}`,

                method: 'GET',
                headers: {
                    'content-type': 'application/json'
                }
            })
            // //console.log(response.data);
            commit('UPDATE_LISTA_PRECIO', response.data)
        } else {
            //console.log('list_mixtos_from_cache')
        }
    },

    async postLista({ rootState }, payload) {
        const data = {
            idColaborador: rootState.users.user.id,
            id_mix_sede: 0, // DEPRECATED
            idSede: rootState.users.user.idSede,
            description: payload.description,
            hours: payload.hours,
            price: parseFloat(payload.price)
        }
        // ////console.log(data);
        const response = await request({
            url: '/api-loopback/tb_price_mixes',

            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            data
        })
        // //////console.log(response.data);
        return response.data
    },
    async delLista({}, payload) {
        // ////console.log(payload);
        const response = await request({
            url: `/api-loopback/tb_price_mixes/${payload.id}`,

            method: 'DELETE',
            headers: {
                'content-type': 'application/json'
            }
        })
        // //////console.log(response.data);
        return response.data
    },
    async updateMixto({}, payload) {
        // ////console.log(payload);
        const data = { ...payload }
        const response = await request({
            url: `/api-loopback/tb_price_mixes/${payload.id}`,

            method: 'PUT',
            headers: {
                'content-type': 'application/json'
            },
            data
        })
        // //////console.log(response.data);
        return response.data
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
