import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/pages/login/Index.vue'
import { versionNameDist } from '@/utils/genericUtils'
import Layout from '@/layout/index.vue'

function lazyComponent(name) {
    return () => ({
        component: import(`@/pages/${name}.vue`)
    })
}

Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/',
            component: Login,
            hidden: true
        },
        {
            path: '/login',
            component: Login,
            hidden: true
        },

        {
            path: '/app',
            component: Layout,
            children: [
                {
                    path: '',
                    name: 'app-ventas',
                    component: lazyComponent('app/Ventas')
                },
                {
                    path: 'reservas',
                    name: 'app-reservas',
                    component: lazyComponent('app/Reservas')
                },
                {
                    path: 'tickets',
                    name: 'app-tickets',
                    component: lazyComponent('app/Tickets')
                },
                {
                    path: 'calendario',
                    name: 'app-calendario',
                    component: lazyComponent('app/Calendario')
                },
                {
                    path: 'facturacion',
                    name: 'app-facturacion',
                    component: lazyComponent('app/facturacion/Index')
                },
                {
                    path: 'cotizaciones',
                    name: 'app-cotizaciones',
                    component: lazyComponent('app/Cotizaciones')
                },
                {
                    path: 'registro',
                    name: 'app-registro',
                    component: lazyComponent('gestion/reportes/index')
                },
                {
                    path: 'controlVentas',
                    name: 'app-controlVentas',
                    component: lazyComponent('app/controlVentas')
                }
            ]
        },
        {
            path: '/logistica',
            component: Layout,
            children: [
                {
                    path: 'orden-produccion/crear',
                    name: 'lo-orden-produccion',
                    component: lazyComponent('logistica/OrdenProduccion')
                },
                {
                    path: 'orden-produccion/lista',
                    name: 'lo-listOrdenProduction',
                    component: lazyComponent('logistica/ListOrdenProduction')
                },
                {
                    path: 'orden-equipamiento/crear',
                    name: 'lo-orden-equipamiento',
                    component: lazyComponent('logistica/OrdenEquipamiento')
                },
                {
                    path: 'orden-equipamiento/lista',
                    name: 'lo-listOrdenEquipamiento',
                    component: lazyComponent('logistica/ListOrdenEquipamiento')
                },
                {
                    path: 'orden-trabajo/crear',
                    name: 'lo-orden-equipamiento',
                    component: lazyComponent('logistica/OrdenTrabajo')
                },
                {
                    path: 'orden-trabajo/lista',
                    name: 'lo-listOrdenTrabajo',
                    component: lazyComponent('logistica/ListOrdenTrabajo')
                },
                {
                    path: 'reparto',
                    name: 'app-reparto',
                    component: lazyComponent('logistica/reparto')
                },
                {
                    path: 'chofer',
                    name: 'app-chofer',
                    component: lazyComponent('logistica/chofer')
                },
                {
                    path: 'inventary',
                    name: 'app-inventary',
                    component: lazyComponent('logistica/Inventary')
                },
                {
                    path: 'inventary/resumen',
                    name: 'app-inventary',
                    component: lazyComponent('logistica/ListInventaryResume')
                },
                {
                    path: 'inventary/list',
                    name: 'app-inventary',
                    component: lazyComponent('logistica/ListInventary')
                },
                {
                    path: 'orden-servicio/list',
                    name: 'app-inventary',
                    component: lazyComponent('logistica/ListOrdenServicio')
                }
            ]
        },
        {
            path: '/gestion',
            component: Layout,
            children: [
                {
                    path: 'caja',
                    name: 'ge-caja',
                    component: lazyComponent('gestion/Caja')
                },
                {
                    path: 'servicios/lista',
                    name: 'app-listService',
                    component: lazyComponent('gestion/servicios')
                },
                {
                    path: 'gastos',
                    name: 'ge-gastosNuevo',
                    component: lazyComponent('gestion/ComprasNuevo')
                },
                {
                    path: 'gastos-old',
                    name: 'ge-gastos-old',
                    component: lazyComponent('gestion/Compras.old')
                },
                {
                    path: 'colaborador',
                    name: 'ge-colaborador',
                    component: lazyComponent('gestion/colaborador')
                },
                {
                    path: 'proveedor',
                    name: 'ge-proveedor',
                    component: lazyComponent('gestion/Proveedor')
                },
                {
                    path: 'asistencia',
                    name: 'ge-asistencia',
                    component: lazyComponent('gestion/Asistencia')
                },
                {
                    path: 'reporte',
                    name: 'ge-reporte',
                    component: lazyComponent('gestion/Reporte')
                },
                {
                    path: 'pagos',
                    name: 'ge-pagos',
                    component: lazyComponent('gestion/Pagos')
                },
                {
                    path: 'dashboard',
                    name: 'ge-Dashboard',
                    component: lazyComponent('gestion/reportes/index')
                },
                {
                    path: 'bancos',
                    name: 'ge-Bancos',
                    component: lazyComponent('gestion/bancos')
                }
            ]
        }
    ],
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        }
        return { x: 0, y: 0 }
    }
})
