const state = {
    showSnackBar: {
        state: false,
        text: 'Agregado exitosamente',
        color: 'grey',
        timeout: 2000
    },
    openModalDefault: {
        state: false,
        text: 'Cargando datos',
        color: 'grey'
    }
}

const mutations = {
    OPEN_BAR(state, payload) {
        state.showSnackBar = payload
    },
    OPEN_MODAL(state, payload) {
        state.openModalDefault = {
            ...payload,
            text: payload.text || 'Cargando datos'
        }
    }
}
const actions = {}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}
