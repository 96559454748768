import { format, subDays, subMonths } from 'date-fns'
import { sortItems, stringToNull } from '@/utils/genericUtils'
import request from '@/utils/request'

const state = {
    listaGastos: [],

    tipoGastos: [],
    listaServicios: []
}
const getters = {
    listaGastos(state, getters, rootState) {
        let newItems = []

        state.listaGastos.map((v) => {
            const resTipo = rootState.utilities.tipoGastos.find((x) => x.id == v.tipoPago)
            const descTipoPago = resTipo ? resTipo.text : ''
            ////console.log('descTipoPago', descTipoPago)
            let descriptions = []
            const items = v.itemGastos ? JSON.parse(v.itemGastos) : []
            items.map((y) => descriptions.push(`${y.description.substr(0, 20)}...`))
            newItems.push({ ...v, descTipoPago, description: descriptions.join() })
        })
        return sortItems(newItems, 'fechaEmision', -1, 2)
    },


}

const mutations = {
    UPDATE_GASTOS(state, payload) {
        state.listaGastos = payload
    },
    UPDATE_SERVICIOS(state, payload) {
        state.listaServicios = payload
    },

    UPDATE_TIPO_GASTOS(state, payload) {
        state.tipoGastos = payload
    }
}
const actions = {
    async getTipoGastos({ commit, rootState }, payload) {
        const { idSede } = rootState.users.user
        let fechaEmision = payload.fechaEmision ? payload.fechaEmision : format(new Date(), 'yyyy-MM-dd')
        fechaEmision = JSON.stringify(fechaEmision)
        // //console.log("GET_GASTOS: " + fechaEmision);
        const response = await request({
            url: `/api-loopback/tb_tipo_gastos`,
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            }
        })

        commit('UPDATE_TIPO_GASTOS', response.data)
    },
    /*     async getLista({ commit, rootState }, payload) {
        const { idSede } = rootState.users.user
        const day = JSON.stringify(format(subMonths(new Date(), 3), 'yyyy-MM-dd'))
        // //console.log("GET_GASTOS: " + fecha);
        const response = await request({
            url: `/api-loopback/tm_compras?filter={"where":{"and":[{"idSede": ${idSede}},{"check_in":{"gt":${day}}}]}}`,
            method: 'GET',
            headers: {
                'content-type': 'application/json',

            }
        })

        commit('UPDATE_GASTOS', response.data)
    }, */

    async getLista({ commit, rootState }, payload) {
        const { idSede } = rootState.users.user
        const fromDate = !stringToNull(payload.fechaFiltro)
            ? [format(subDays(new Date(), 31), 'yyyy-MM-dd'), format(new Date(), 'yyyy-MM-dd')]
            : payload.fechaFiltro
        console.log('fromDate', fromDate)
        const { data: result } = await request({
            url: `/api-loopback/tm_compras/getGastosByDate/${idSede}/${fromDate[0]}/3/1/${fromDate[1]}`,
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            }
        })

        console.log('get Gastos ', result)

        commit('UPDATE_GASTOS', result)
    },
    async getServicios({ commit, rootState }, payload) {
        //console.log('rootState.users.user', rootState.users.user)
        const { idSede } = rootState.users.user

        const { data } = await request({
            url: `/ms/migraciones/v1.0/servicios?idSede=${idSede}`,
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            }
        })

        const filterServices = data.data.filter((x) => [10, 11, 12, 24].includes(x.category_id))
        commit('UPDATE_SERVICIOS', filterServices)
    },
    async deleteServicios({ commit, rootState, dispatch }, payload) {
        console.log('payload--', payload)
        const { idSede } = rootState.users.user
        await request({
            url: `/ms/migraciones/v1.0/servicios?idSede=${idSede}`,
            method: 'PUT',
            headers: {
                'content-type': 'application/json'
            },
            data: {
                ...payload,
                status: 0
            }
        })
        location.reload()
    },
    async postServicios({ commit, rootState }, payload) {
        //console.log('rootState.users.user', rootState.users.user)
        function convertH2M(timeInHour) {
            var timeParts = timeInHour.split(':')
            return Number(timeParts[0]) * 60 + Number(timeParts[1])
        }

        const { idSede } = rootState.users.user
        const { details, duration, value, tipoServicio } = payload
        //console.log('payload', payload)
        const _data = {
            value: Number(value),
            description: details,
            hours: convertH2M(duration),
            tipoServicio,
            idSede
        }
        console.log('_data', _data)
        const { data } = await request({
            url: `/ms/migraciones/v1.0/servicios`,
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            data: _data
        })

        console.log('get SERVICIOS ', data.data)
    },
    async patchServicios({ commit, rootState }, payload) {
        //console.log('rootState.users.user', rootState.users.user)
        function convertH2M(timeInHour) {
            var timeParts = timeInHour.split(':')
            return Number(timeParts[0]) * 60 + Number(timeParts[1])
        }
        const { idSede } = rootState.users.user
        //console.log('payload', payload)
        const { value, details, duration, id, tipoServicio } = payload
        const _data = {
            value: Number(value),
            description: details,
            hours: convertH2M(duration),
            idSede,
            tipoServicio,
            id
        }
        console.log('_data_patch', _data)
        const { data } = await request({
            url: `/ms/migraciones/v1.0/servicios`,
            method: 'PATCH',
            headers: {
                'content-type': 'application/json'
            },
            data: _data
        })

        //console.log('get SERVICIOS ', data.data)
    },
    async postGastos({ rootState, state }, payload) {
        const { idSede } = rootState.users.user
        const fechaEmision = payload.fechaEmision ? payload.fechaEmision : format(new Date(), 'yyyy-MM-dd')

        /* if (rootState.users.user.id && !state.sede.id) { */
        const data = {
            idSede,
            ...payload,
            fechaEmision
        }

        // //console.log("POST_SEDE");
        // //console.log(data);
        const response = await request({
            url: '/api-loopback/tm_compras',

            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            data
        })
        // //console.log(response.data);
    },
    async deleteGasto({ rootState, state }, payload) {
        // //console.log("DELETE_SEDE");
        const response = await request({
            url: `/api-loopback/tm_compras/${payload.id}`,

            method: 'DELETE',
            headers: {
                'content-type': 'application/json'
            }
        })
        // //console.log(response.data);
    },
    async updateGastoxId({ commit, rootState }, payload) {
        const response = await request({
            url: `/api-loopback/tm_compras/${payload.id}`,

            method: 'PUT',
            headers: {
                'content-type': 'application/json'
            },
            data: {
                ...payload
            }
        })

    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
