import request from '@/utils/request'
import { sortItems } from '@/utils/genericUtils'
import axios from 'axios'

const state = {
    listaClientes: []
}
const getters = {
    listaClientes(state, getters, rootState) {
        console.log('getters listaClientes ', state.listaClientes)
        const departamentos = rootState.sedes.departamentos || []
        let items = []
        //console.log('state.listaClientes', state.listaClientes)
        //if (state.listaClientes.length > 0) {
        state.listaClientes.map((x) => {
            const ubigeo = x.ubigeo ? x.ubigeo.substr(0, 2) : ''
            ////console.log('x', x.ubigeo)
            const fnDepartamento = departamentos.find((y) => y.id == ubigeo)
            ////console.log('fnDepartamento ', fnDepartamento)
            items.push({
                ...x,
                _ubigeo: fnDepartamento ? fnDepartamento.name : null,
                //name: `${x.number}-${x.name}`,
                rename: `${x.number}-${x.name}`,
                defaultName: x.name
            })
        })
        //}
        console.log('items_listaClientes', items)
        return sortItems(items, 'id', 1)
    }
}

const mutations = {
    UPDATE_CLIENTES(state, payload) {
        console.log('mutations_lockupdateclient')
        state.listaClientes = payload
    }
}
const actions = {
    async getListaCli({ commit, state, rootState }, payload) {
        //console.log('GET_CLIENTE')
        const { idBussines, idSede, id_role } = rootState.users.user
        //    if (state.listaClientes.length < 1 || payload) {
        const active = payload['active']

        // if (state.listaClientes.length < 1 || active === 0) {
        const { data } = await request({
            url: `/ms/migraciones/v2.0/clientes?idSede=${idSede}&idBussines=${idBussines}&id_role=${id_role}`,
            method: 'GET',
            headers: {
                'content-type': 'application/json'
                //
            }
        })
        console.log('clientes_from_server ', data.data)

        commit('UPDATE_CLIENTES', data.data)
        // } else {
        //     console.log('clientes_from_cache')
        // }
    },
    async postCliente({ rootState }, payload) {
        const { idSede } = rootState.users.user

        const data = {
            ...payload,
            fecha_nacimiento: payload.fecha_nacimiento
        }

        const response = await request({
            url: '/api-loopback/tb_clientes',
            method: 'POST',
            headers: {
                'content-type': 'application/json'
                //
            },
            data
        })
        //console.log('POST_CLIENTE', response.data)
        return response.data
    },
    async patchClient({ rootState }, payload) {
        const sede = rootState.users.user.idSede
        const { full_name, telefono, direccion, correo, department_id, province_id, district_id, id } = payload
        console.log('payload_patchClient', payload)
        const { data } = await request({
            url: `/ms/migraciones/v2.0/persons?numeroDocumento=${payload.numeroDocumento}&idSede=${sede}`,
            method: 'PATCH',
            headers: {
                'content-type': 'application/json'
            },
            data: {
                name: full_name,
                telephone: telefono,
                address: direccion,
                email: correo,
                department_id,
                province_id,
                district_id,
                id
            }
        })
        console.log('data', data)
    },
    async getClient({ state, rootState }, payload) {
        const sede = rootState.users.user.idSede

        const { data } = await request({
            url: `/ms/migraciones/v2.0/persons?numeroDocumento=${payload}&idSede=${sede}`,
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            }
        })
        console.log('data', data[0])
        if (data.length > 0) {
            return data[0]
        } else {
            return null
        }
    },
    async obtenerDataClient({ rootState }, payload) {
        let tipoDocumento = payload.length == 8 ? 'dni' : 'ruc'
        //console.log('tipoDocumento', tipoDocumento)
        const { data } = await request({
            url: `/ms/datosperu/v1.1/${tipoDocumento}/${payload}`,
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            }
        })
        return tipoDocumento == 'dni' ? data.data.nombre_completo : data.data.nombre_o_razon_social
    },
    async createCliente({ rootState }, payload) {
        const { idSede, idBussines } = rootState.users.user

        const data = {
            ...payload,
            idBussines,
            idSede,
            fecha_nacimiento: payload.fecha_nacimiento
        }
        //console.log('data', data)
        const response = await request({
            url: '/api-orquestador/clientes/v2.0/kunte/create',
            method: 'POST',
            headers: {
                'content-type': 'application/json'
                //
            },
            data
        })
        //console.log('POST_CLIENTE', response.data)
        return response.data
    },
    async crearClienteFact({ rootState }, payload) {
        const { idSede, idBussines } = rootState.users.user
        console.log('payload', payload)
        //console.log('payload', payload)
        const response = await request({
            url: '/ms/migraciones/v2.0/clientes',
            method: 'POST',
            headers: {
                'content-type': 'application/json'
                //
            },
            data: { ...payload, idSede }
        })

        //console.log('POST_CLIENTE', response.data)
        return response.data
    },

    async enviarEmail({ rootState }, payload) {
      console.log("payload---",payload)
        const { emailClient, check_in, idSede,sedes, id, full_name, hourService
        } = payload
        const reservaSede = Object.values(sedes).find(v => v.id == idSede);
        const { data } = await  request({
            url: '/api-orquestador/email/v2.0/notificacion',
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            data: {
                email: emailClient,
                asunto: "Confirmacion De Reserva",
                parametros: {
                  nombreCompletos: full_name,
                  serviceId: 3,
                  archivosAdjuntos: "",
                  id: id,
                  hourService,
                  fechaCreacion:check_in,
                  nombreSede: reservaSede.sede_name_id,
                  idSede:idSede
              }
            }
        })
        console.log('response', data)
        return data
    },

    async updateCliente({ rootState }, data) {
        const { idSede, idBussines } = rootState.users.user

        const response = await request({
            url: `/api-loopback/tb_clientes/updateCliPersona`,
            method: 'POST',
            headers: {
                'content-type': 'application/json'
                //
            },
            data
        })
        //console.log('POST_CLIENTE')
        //console.log(response.data)
    },
    async deleteClientes({ rootState }, payload) {
        // //console.log("GET_CLIENTE");
        const response = await request({
            url: `/api-loopback/tb_clientes/${payload.id}`,
            method: 'DELETE',
            headers: {
                'content-type': 'application/json'
                //
            }
        })
        //console.log('DELETE_CLIENTE')
        //console.log(response.data)
    },
    async getClienteExternal({ commit, state, rootState }, payload) {
        console.log('payload', payload)
        const tipodocumento = payload.idTipoDocumento == 1 ? 'dni' : 'ruc'
        const { data } = await axios({
            url: `https://ms.prod.innout.cloud/api/${tipodocumento}/${payload.numeroDocumento}`,
            method: 'GET',
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer 86696e9d-beab-4c79-aa54-2d5a64f54f97`
            }
        })
        console.log('clientes_external_from_server', data.data)
        return data.data
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
