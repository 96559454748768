import request from '@/utils/request'
import { formatInTimeZone, toDate, format, parseISO, addMinutes } from 'date-fns'
import { sortItems, stringToNull, downloadFile } from '@/utils/genericUtils'

const state = {
    listaProductos: [],
    listaEstados: [],
    listaResumen: [],

    historialEstado: [],
    listaProductosOut: [],
    listInventory: [],
    dataproductoWarehouse: [],
    inventory: [],
    listTransaction: [],
    ordenProduccion: [],
    ordenEquipamiento: [],
    ordenServicio: []
}

const getters = {
    ordenProduccion(state, getters, rootState) {
        let newItems = []

        state.ordenProduccion.map((v) => {
            ////console.log('descTipoPago', descTipoPago)
            let descriptions = []
            const items = v.itemGastos ? JSON.parse(v.itemGastos) : []
            items.map((y) => descriptions.push(`${y.description.substr(0, 20)}...`))
            newItems.push({ ...v, description: descriptions.join() })
        })
        return sortItems(newItems, 'fechaEmision', -1, 2)
    },
    ordenEquipamiento(state, getters, rootState) {
        let newItems = []

        state.ordenEquipamiento.map((v) => {
            //console.log('laV', v)
            ////console.log('descTipoPago', descTipoPago)
            let descriptions = []
            const items = v.itemGastos ? JSON.parse(v.itemGastos) : []
            items.map((y) => descriptions.push(`${y.description.substr(0, 20)}...`))
            newItems.push({ ...v, description: descriptions.join() })
        })
        //console.log('newItems', newItems)
        return sortItems(newItems, 'fechaEmision', -1, 2)
    },
    listaProductosAvailables(state) {
        return state.listaProductos.filter((val) => {
            if (val.id_category != '4' && val.id_category != '5') {
                return val
            }
        })
    },
    listaCortesia(state) {
        return state.listaProductos.filter((val) => {
            if (val.id_category == '4' || val.id_category == '5') {
                return val
            }
        })
    },
    listaProductosOut(state) {
        return state.listaProductosOut.filter((val) => {
            if (val.id_category != '4' && val.id_category != '5') {
                return val
            }
        })
    },
    listaCortesiaOut(state) {
        return state.listaProductosOut.filter((val) => {
            if (val.id_category == '4' || val.id_category == '5') {
                return val
            }
        })
    }
}

const mutations = {
    UPDATE_ORDEN_SERVICIO(state, payload) {
        state.ordenServicio = payload
    },
    UPDATE_LISTA(state, payload) {
        state.listaProductos = payload
    },
    UPDATE_HISTORIAL_ESTADO(state, payload) {
        state.historialEstado = payload
    },
    UPDATE_LISTA_ESTADOS(state, payload) {
        state.listaEstados = payload
    },
    UPDATE_LISTA_OUT(state, payload) {
        state.listaProductosOut = payload
    },
    UPDATE_LIST_INVENTORY(state, payload) {
        state.listInventory = payload
    },
    UPDATE_DATAPRODUCTO_WAREHOUSE(state, payload) {
        state.dataproductoWarehouse = payload
    },
    UPDATE_LISTA_INVENTORY(state, payload) {
        state.inventory = payload
    },
    UPDATE_TRANSACTION_INVENTORY(state, payload) {
        state.listTransaction = payload
    },
    UPDATE_ORDENES_PRODUCCION(state, payload) {
        state.ordenProduccion = payload
    },
    UPDATE_ORDENES_EQUIPAMIENTO(state, payload) {
        state.ordenEquipamiento = payload
    },
    UPDATE_ORDENES_RESUMEN(state, payload) {
        state.listaResumen = payload
    }
}

const actions = {
    async getListaResumenReport({ commit, rootState }, payload) {
        //console.log('fromDate', fromDate)
        const { data } = await request({
            url: `/ms/inventario/private/1.0/report/resumen`,
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            data: {}
        })

        commit('UPDATE_ORDENES_RESUMEN', data.data)
    },
    async getReport({ commit, rootState }, payload) {
        const response = await request({
            url: `/ms/carbone/v1.0/report/21`,
            method: 'POST',
            responseType: 'blob', // important
            headers: {
                //
                /* "content-type":"application/octet-stream" */
            },
            data: payload
        })
        console.log('response', response.data)
        const name = `ORDEN TRABAJO Nro ${payload.orden}`
        await downloadFile(response.data, name, 'docx')
    },
    async getListaOrdenProduccion({ commit, rootState }, payload) {
        const fromDate = !stringToNull(payload.fechaFiltro)
            ? [format(subDays(new Date(), 31), 'yyyy-MM-dd'), format(new Date(), 'yyyy-MM-dd')]
            : payload.fechaFiltro
        //console.log('fromDate', fromDate)
        const { data: result } = await request({
            url: `/ms/inventario/private/1.0/obtener/ordenes/produccion`,
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            data: {
                date1: fromDate[0],
                date2: fromDate[1]
            }
        })

        //console.log('get OP ', result)

        commit('UPDATE_ORDENES_PRODUCCION', result.data)
    },
    async getListaOrdenEquipamiento({ commit, rootState }, payload) {
        const fromDate = !stringToNull(payload.fechaFiltro)
            ? [format(subDays(new Date(), 31), 'yyyy-MM-dd'), format(new Date(), 'yyyy-MM-dd')]
            : payload.fechaFiltro
        //console.log('fromDate', fromDate)
        const { data: result } = await request({
            url: `/ms/inventario/private/1.0/obtener/ordenes/equipamiento`,
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            data: {
                date1: fromDate[0],
                date2: fromDate[1]
            }
        })

        //console.log('get OP ', result)

        commit('UPDATE_ORDENES_EQUIPAMIENTO', result.data)
    },
    async getHistorialEstado({ commit, rootState, state }, payload) {
        try {
            const { data } = await axios({
                url: `/api-loopback/tb_venta_estados?filter={"where":{"and":[{"idVenta":${payload}}]}}`,
                method: 'GET',
                headers: {
                    'content-type': 'application/json'
                }
            })
            console.log('getHistorialEstado', data)

            const new_data = []
            data.map((x) => {
                const fecha = format(addMinutes(new Date(x.created_at), new Date(x.created_at).getTimezoneOffset()), 'yyyy-MM-dd HH:mm:ss')

                new_data.push({
                    ...x,
                    estado: state.listaEstados.find((y) => y.id === x.idEstado).description,
                    fecha
                })
            })

            commit('UPDATE_HISTORIAL_ESTADO', new_data.reverse())
            return new_data
        } catch (error) {
            console.log('error ', error)
        }
    },
    async deleteEstado({ commit, rootState }, payload) {
        const { data } = await axios({
            url: `/api-loopback/tb_venta_estados/${payload}`,
            method: 'DELETE',
            headers: {
                'content-type': 'application/json'
            }
        })
    },
    async updateEstado({ commit, rootState }, payload) {
        const { idEstado, idVenta } = payload
        const { nombres, apellidos } = rootState.users.user

        //console.log('payload_update estado', payload)
        await Promise.all([
            await axios({
                url: `/api-loopback/tm_ventas/${idVenta}`,
                method: 'PATCH',
                headers: {
                    'content-type': 'application/json'
                },
                data: {
                    idEstado
                }
            }),
            await axios({
                url: `/api-loopback/tb_venta_estados`,
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                data: {
                    idVenta,
                    idEstado,
                    user: nombres + ', ' + apellidos
                }
            })
        ])
    },
    async estados({ commit, state, rootState }) {
        if (state.listaEstados.length < 1) {
            const response = await request({
                url: `/api-loopback/tb_estados`,
                method: 'GET',
                headers: {
                    'content-type': 'application/json'
                }
            })
            //console.log(response.data)
            commit('UPDATE_LISTA_ESTADOS', response.data)
        }
    },
    async getItemWarehouses({ rootState, commit, state }) {
        //console.log('active', active)
        const { idSede } = rootState.users.user
        //console.log('rootState.users', rootState.users)
        const { data } = await request({
            url: `/ms/migraciones/v1.0/${idSede}/items`,
            method: 'get',
            headers: {
                'content-type': 'application/json'
            }
        })
        console.log('getItemWarehouses---', data.data)

        commit('UPDATE_DATAPRODUCTO_WAREHOUSE', data.data)
    },
    async getItemInventory({ rootState, commit, state }) {
        //console.log('active', active)
        const { idSede } = rootState.users.user
        //console.log('rootState.users', rootState.users)
        const { data } = await request({
            url: `/ms/migraciones/v1.0/${idSede}/inventory`,
            method: 'get',
            headers: {
                'content-type': 'application/json'
            }
        })
        console.log('getItemInventory---', data.data)
        commit('UPDATE_LIST_INVENTORY', data.data)
    },
    async postItemAddInventory({ rootState, commit, state }, payload) {
        //console.log('active', active)
        const { idSede } = rootState.users.user
        //console.log('rootState.users', rootState.users)
        const { data } = await request({
            url: `/ms/migraciones/v1.0/queue/${idSede}/item`,
            method: 'post',
            headers: {
                'content-type': 'application/json'
            },
            data: payload
        })
        return data
    },
    async getItemAddInventory({ rootState, commit, state }) {
        //console.log('active', active)
        const { idSede } = rootState.users.user
        //console.log('rootState.users', rootState.users)
        const { data } = await request({
            url: `/ms/migraciones/v1.0/queue/${idSede}/item`,
            method: 'get',
            headers: {
                'content-type': 'application/json'
            }
        })
        console.log('dataxxx', data)
        commit('UPDATE_TRANSACTION_INVENTORY', data.data)
    },
    async closeOrdenProduccion({ rootState, rootGetters, dispatch }, payload) {
        //console.log('reporteOrdenProduccion', payload)
        const { id } = payload
        await request({
            url: `/ms/inventario/private/1.0/ordenes/produccion`,
            method: 'PUT',
            headers: {
                'content-type': 'application/json'
            },
            data: {
                data: payload.data,
                id
            }
        })
    },
    async closeOrdenEquipamiento({ rootState, rootGetters, dispatch }, payload) {
        //console.log('reporteOrdenProduccion', payload)
        const { id } = payload
        await request({
            url: `/ms/inventario/private/1.0/ordenes/equipamiento`,
            method: 'PUT',
            headers: {
                'content-type': 'application/json'
            },
            data: {
                data: payload.data,
                id
            }
        })
    },

    async postAddOT({ commit, state, rootState }, payload) {
        const { idBussines } = rootState.users.user
        const { data } = await request({
            url: `/ms/inventario/private/1.0/orden-trabajo/${idBussines}`,
            method: 'post',
            headers: {
                'content-type': 'application/json'
            },
            data: payload
        })
        //console.log(response.data);
        return data
    },
    async getListOT({ commit, state, rootState }, payload) {
        const { idBussines } = rootState.users.user
        const { data } = await request({
            url: `/ms/inventario/private/1.0/orden-trabajo/${idBussines}`,
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            }
        })
        //console.log(response.data);
        return data
    },
    async putListOT({ commit, state, rootState }, payload) {
        const { data } = await request({
            url: `/ms/inventario/private/1.0/orden-trabajo/${payload.id}`,
            method: 'PUT',
            headers: {
                'content-type': 'application/json'
            },
            data: payload
        })
        //console.log(response.data);
        return data
    },
    async getListOS({ commit, state, rootState }, payload) {
        const { idBussines } = rootState.users.user
        const { data } = await request({
            url: `/ms/inventario/private/1.0/orden/servicio/${idBussines}`,
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            }
        })
        //console.log(response.data);
        commit('UPDATE_ORDEN_SERVICIO', data.data)

        return data
    },
    async postAddOS({ commit, state, rootState }, payload) {
        const { idBussines, nombres } = rootState.users.user
        const { data } = await request({
            url: `/ms/inventario/private/1.0/orden/servicio`,
            method: 'post',
            headers: {
                'content-type': 'application/json'
            },
            data: { ...payload, idBussines, vendedor: nombres }
        })
        //console.log(response.data);
        return data
    },
    async putListOS({ commit, state, rootState }, payload) {
        const { data } = await request({
            url: `/ms/inventario/private/1.0/orden/servicio/${payload.id}`,
            method: 'PUT',
            headers: {
                'content-type': 'application/json'
            },
            data: { data: payload.data }
        })
        //console.log(response.data);
        return data
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
